/* eslint-disable no-nested-ternary */
import { PLAYER_TYPE } from 'enums'
import { MARK_TYPE } from 'config/constants/preview'
import { deleteTimelineFragment, rippleDeleteTimelineFragment, undoDeleteTimelineFragment } from '~/actions/timeline'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'
import { getObjectFields, timelineTimeToSeconds } from '~/Util'
import { setPreviewMarkers } from '~/actions/preview'

export class DeleteTimelineFragmentAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.DeleteTimelineFragmentAction, payload, ...params)
    this.adjustableAssetDataObjects = payload.adjustableAssetDataObjects
    this.deleteAssets = payload.deleteAssets.map(asset => {
      const objectAsset = getObjectFields(asset)
      if (objectAsset.thumbnail) objectAsset.thumbnail = ''
      return objectAsset
    })
    this.layerIdsOfDeleteAssets = payload.layerIdsOfDeleteAssets
    this.prevLayersByIndex = payload.layersMap
    this.markInTimeline = payload.markInTimeline
    this.markOutTimeline = payload.markOutTimeline
    this.shiftAssetIds = payload.shiftAssetIds
    this.deltaShiftTime = payload.deltaShiftTime
    this.prevExistsAssetIds = payload.prevExistsAssetIds
  }

  * undo() {
    yield undoDeleteTimelineFragment({
      adjustableAssetDataObjects: this.adjustableAssetDataObjects,
      deleteAssets: this.deleteAssets,
      layerIdsOfDeleteAssets: this.layerIdsOfDeleteAssets,
      layersMap: this.prevLayersByIndex,
      shiftAssetIds: this.shiftAssetIds,
      deltaShiftTime: this.deltaShiftTime,
      prevExistsAssetIds: this.prevExistsAssetIds,
    })
    yield setPreviewMarkers({
      [PLAYER_TYPE.TIMELINE]: {
        [MARK_TYPE.IN]: timelineTimeToSeconds(this.markInTimeline),
        [MARK_TYPE.OUT]: timelineTimeToSeconds(this.markOutTimeline),
      },
    }, { preview: PLAYER_TYPE.TIMELINE, updateAsset: false })
  }

  * redo() {
    if (this.deltaShiftTime) {
      yield rippleDeleteTimelineFragment({
        markIn: this.markInTimeline,
        markOut: this.markOutTimeline,
      })
    } else {
      yield deleteTimelineFragment({
        markIn: this.markInTimeline,
        markOut: this.markOutTimeline,
      })
    }
  }

}
