import React, { FC, useContext } from 'react'
import { ProjectMenuContext } from 'contexts/ProjectContext'
import { v4 as uuidv4 } from 'uuid'
import { TranslationContext } from 'contexts/TranslationContext'

interface IProps {
  projectId: string
  rename(): void,
  remove(): void,
  copy(): void,
  exportProject(): void,
}

export const ProjectContextMenuComponent:FC<IProps> = ({ projectId, rename, remove, copy, exportProject }) => {
  const { contextMenu } = useContext(ProjectMenuContext)
  const { t } = useContext(TranslationContext)
  if (!contextMenu.visible
    || (projectId
    && (projectId !== contextMenu.projectId))) return null

  const menuItems = [
    { id: uuidv4(), text: t('PROJECTS_TITLE_TEXT_RENAME'), cb: rename },
    { id: uuidv4(), text: t('PROJECTS_TITLE_TEXT_COPY'), cb: copy },
    { id: uuidv4(), text: t('PROJECTS_TITLE_TEXT_DELETE'), cb: remove },
    { id: uuidv4(), text: t('PROJECTS_TITLE_TEXT_EXPORT'), cb: exportProject },
  ]
  return (
    <div
      className="project-item__context-menu context-menu"
      style={{
        position: 'absolute',
        top: contextMenu.top,
        left: contextMenu.left,
      }}
    >
      {menuItems.map(menuItem => (
        <div key={menuItem.id} onClick={menuItem.cb} className="context-menu__item">
          {menuItem.text}
        </div>
      ))}
    </div>
  )
}
