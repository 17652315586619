import { FC, useEffect } from 'react'

interface IProps {
  onConfirm():void
}

export const DefaultImport: FC<IProps> = ({ onConfirm }) => {
  useEffect(() => {
    onConfirm()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
