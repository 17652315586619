import { useCallback } from 'react'
import { resetMarkers, setActivePreview, setInOutPointsTimelineAsset, setShowInOutPoints } from 'actions/preview'
import { PLAYER_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import { useSelector } from 'react-redux'
import { selectorActivePreview, selectorSplitPlayers, selectPreviewMode } from 'selectors/preview'

export const usePreviewSwitcher = () => {
  const isSplittedPreview = useSelector(selectorSplitPlayers)
  const activePreviewMode = useSelector(selectPreviewMode)
  const activePreview = useSelector(selectorActivePreview)

  const setTimelinePreview = useAction(setActivePreview, PLAYER_TYPE.TIMELINE)
  const resetIOPointsTimelineAsset = useAction(setInOutPointsTimelineAsset, null)
  const offIOPoints = useAction(setShowInOutPoints)
  const resetIOTimelineAssetMarkers = useAction(resetMarkers, { preview: PLAYER_TYPE.MEDIA })

  const switchPreviewMediaToTimeline = useCallback(() => {
    setTimelinePreview()
    resetIOPointsTimelineAsset()
    offIOPoints(false, PLAYER_TYPE.MEDIA)
    resetIOTimelineAssetMarkers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    switchPreviewMediaToTimeline,
    isSplittedPreview,
    activePreviewMode,
    activePreview,
  }
}
