import Cookies from 'universal-cookie'

let cookies = null

function getInstance() {
  if (cookies == null) {
    cookies = new Cookies()
  }
  return cookies
}

export default { getInstance }
