
import Cookies from './service'

const cookies = Cookies.getInstance()

export function getCookiesBoolean(name, defaultValue) {
  const value = cookies.get(name)
  if (value === undefined) {
    return defaultValue
  }
  return value === 'true'
}
