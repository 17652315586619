import { useOpenProject } from './useOpenProject'

export const useProjectItemProps = (project, { onClick }) => {
  const openProject = useOpenProject()
  return {
    onDoubleClick: async () => {
      await openProject(project?.id)
    },
    onClick,
  }
}
