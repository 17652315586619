import { MARK_TYPE } from 'config/constants/preview'
import { PLAYER_TYPE } from 'enums'
import { isNil, isNumber } from 'lodash'
import { MediaAsset } from 'models/Asset'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getIsMovingSlider, selectSliderTime } from 'selectors'
import { PlayerType } from 'types/common'
import { timelineTimeToSeconds } from 'Util'
import { MouseMovementDataType } from './types'

type T = {
  onRewind(sec: number): void,
  preview: PlayerType,
  activePreview: PlayerType,
  inOutPointsTimeLineAsset?: MediaAsset,
  timeCodeMarkers: Record<'markIn' | 'markOut', number>
  mouseMovementData: React.MutableRefObject<MouseMovementDataType>
}

// Set source preview player time from timeline resource time
export const useIOPointsTimelineAssetAsSourcePreviewClip = ({
  onRewind,
  preview,
  inOutPointsTimeLineAsset,
  timeCodeMarkers,
  mouseMovementData,
}: T) => {
  const sliderTime: number = useSelector(selectSliderTime)
  const isMovingSlider: boolean = useSelector(getIsMovingSlider)
  const { startTime, mediaStart, mediaFileDuration } = inOutPointsTimeLineAsset ?? {}

  /* Update only when the slider position changes and preview is PLAYER_TYPE.MEDIA.
   * Do not update if at this moment we change i/o points
  */
  useMemo(() => {
    if (preview === PLAYER_TYPE.MEDIA && isMovingSlider && !mouseMovementData.current.markType) {
      let time: number | null = null
      if (isNumber(startTime) && isNumber(mediaStart)) {
        time = timeCodeMarkers[MARK_TYPE.IN]
          ? timeCodeMarkers[MARK_TYPE.IN]
          : timeCodeMarkers[MARK_TYPE.IN] + timelineTimeToSeconds(mediaStart)
        if ((sliderTime >= (startTime - mediaStart)) && (sliderTime <= (startTime + (mediaFileDuration ?? 0)))) {
          time = timelineTimeToSeconds(sliderTime - startTime + mediaStart)
        }
      }
      setTimeout(() => {
        if (!isNil(time)) {
          onRewind(time)
        }
      })
    }
  }, [
    sliderTime,
    startTime,
    mediaStart,
    timeCodeMarkers,
    preview,
    onRewind,
    mouseMovementData,
    mediaFileDuration,
    isMovingSlider,
  ])
}
