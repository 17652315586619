import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as Actions from '~/actions'
import { AppRoutes } from '~/AppRoutes'
import { useAction } from '~/hooks'

export const useOpenProject = () => {
  const resetEditor = useAction(Actions.resetEditorBeforeProjectActivated)
  const loadProject = useAction(Actions.loadProject.loadProject)
  const history = useHistory()

  const openProject = useCallback(async projectId => {
    // NOTE: The project.id won't be passed, because it will be handled from route (history.push)
    // name just used for showing while project loading
    if (projectId) {
      await resetEditor()
      await loadProject(projectId)
      history.push(`${AppRoutes.Projects}/${projectId}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return openProject
}
