import Cookies from './service'
import { PROJECT_EXPORT_TYPE } from '~/enums'
import { defaultCookiesParamsMaxTime } from './defaultCookiesParams'
import { getCookiesBoolean } from './getBooleanWithDefaultValue'

const cookies = Cookies.getInstance()
const EXPORT_TYPE = 'projectExportType'
const INCLUDE_MEDIA = 'projectExportIncludeMedia'

export function set({ exportType, includeMedia }) {
  if (exportType !== undefined) {
    cookies.set(EXPORT_TYPE, exportType, defaultCookiesParamsMaxTime)
  }
  if (includeMedia !== undefined) {
    cookies.set(INCLUDE_MEDIA, includeMedia, defaultCookiesParamsMaxTime)
  }
}

export function get() {
  return { exportType: cookies.get(EXPORT_TYPE) || PROJECT_EXPORT_TYPE.JSON,
    includeMedia: getCookiesBoolean(INCLUDE_MEDIA, true) }
}
