import { addCopiedAssetsToTimeLine } from '~/actions/projectData/addAssetToTimeline'
import { rebuildAssets } from '~/actions/projectData/mappings/getAssetMapping'
import { deleteAssets, deleteLayers, insertLayers, onScrollX, rewind } from '~/actions/timeline'
import { AssetHistoryAction } from '~/models/HistoryActions/AssetHistoryAction'
import { HistoryActionType } from '~/models/HistoryActions/HistoryActionType'
import Layer from '~/models/Layer'
import { TransitionAsset } from '~/models/Asset'
import { getObjectFields } from '~/Util'
import { clearAssetsSelection } from '~/actions/layer'

export class CopiedAssetCreationAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.CopiedAssetCreationAction, payload, ...params)
    const { newLayersIds, prevSliderTime, prevLayersMap,
      newSliderTime, prevSelectedAssetIds, transitions, insertionLayerIndex, copyType } = payload
    this._newLayersIds = newLayersIds
    this._prevSliderTime = prevSliderTime
    this._newSliderTime = newSliderTime
    this._transitions = transitions || []
    this._prevSelectedAssetIds = prevSelectedAssetIds
    this._insertionLayerIndex = insertionLayerIndex || 0
    this._copyType = copyType
    this._prevLayersMap = prevLayersMap
  }

  get prevSliderTime() {
    return this._prevSliderTime
  }

  get prevSelectedAssetIds() {
    return this._prevSelectedAssetIds
  }

  get newLayersIds() {
    return this._newLayersIds
  }

  /**
  * @param {string[]} layers
  */
  set newLayersIds(layers) {
    this._newLayersIds = layers
  }

  get transitions() {
    return this._transitions
  }

  get newSliderTime() {
    return this._newSliderTime
  }

  get insertionLayerIndex() {
    return this._insertionLayerIndex
  }

  get copyType() {
    return this._copyType
  }

  get prevLayersMap() {
    return this._prevLayersMap
  }

  * undo() {
    const assets = rebuildAssets(this.assets)
    if (!this.newLayersIds) {
      yield deleteAssets(assets, this.prevSelectedAssetIds)
    } else {
      yield deleteLayers(this.newLayersIds)
      yield deleteAssets(assets, this.prevSelectedAssetIds)
    }
    yield rewind(this.prevSliderTime)
    const scrollOffset = window.innerWidth / 2
    yield onScrollX(this.prevSliderTime, scrollOffset)
    yield clearAssetsSelection()
  }

  * redo() {
    if (this.newLayersIds) {
      const insertedLayers = []
      this.newLayersIds.forEach(id => {
        const layer = new Layer()
        layer.id = id
        insertedLayers.push(layer)
      })
      yield insertLayers(this.insertionLayerIndex, insertedLayers, { addToHistory: false })
    }
    const rebuildTransitions = this.transitions
      .map(transition => new TransitionAsset(getObjectFields(transition)))
    yield addCopiedAssetsToTimeLine(
      this.assets, rebuildTransitions, { prevLayersMap: this.prevLayersMap, sliderTime: undefined }
    )
    yield rewind(this.newSliderTime)
  }

}
