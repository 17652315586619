import { HOTKEYS, HOTKEY_CATEGORY } from 'enums'

// This and all after versions is not supported by backward compatibility
export const PROJECT_BACK_COMPATIBILITY_BEFORE = '1.3.0'
export const newProjectId = 'new'
export const newProjectName = 'Untitled'
export const videoUrlTimeFragmentParam = '#t'
export const defaultPlayerVolume = 1
export const MAX_ASSET_VOLUME = 4

// HISTORY ACTIONS (UNDO-REDO)

export const colorPickerChangingSettings = [ 'outlineColor', 'color', 'shadowColor' ]
export const continiousChangingSettings = [ 'opacity',
  'outlineWidth',
  'text',
  'background',
  'shadowBlur',
  'similarity',
  'chromaKeyColor',
  'smoothness',
  'spillReduction',
  'font',
  'fontSize',
  'textTransform',
  'fontStyle',
  'align',
  'position',
  'disableOutline',
  'disableShadow',
  'shadowOffset',
]

export const continiousChangingAssetParams = [ 'volume' ]
export const continiousChangingSettingsDebounceMs = 500
export const localStorageActiosHistoryProjectId = 'actiosHistoryProjectId'
export const localStoragePendingHistoryActions = 'pendingHistoryActions'
export const localStoragePendingActionEvents = 'pendingActionEvents'
export const LOCAL_STORAGE_SOURCE_FILES_PARAMS = 'sourceFilesParams'
export const STORAGE_SOURCE_FILES_NAV_HISTORY = 'sourceFilesHistory'
export const STORAGE_SOURCE_FILES_NAV_HISTORY_INDEX = 'sourceFilesHistoryIndex'

export const MAX_NAVIGATION_HISTORY_COUNT = 100
export const onlineTestCooldownMs = 5000
export const nearTimeBufferingDiapasonMs = 10_000
export const nearTimeBufferingDiapasonS = nearTimeBufferingDiapasonMs / 1000

export const KEY_Z = 'KeyZ'
export const KEY_Y = 'KeyY'
export const KEY_SPACE = 'Space'

export const DUPLICATED_PROJECT_NAME_POSTFIX = ' copy'

export const DRAGGING_HOVER_ACTIVITY_OFFSET = 10

export const DEFAULT_IMAGE_DURATION = 100_000_000

export const DEFAULT_FOLDER_NAME = 'Untitled'

export const MIN_TIMELINE_ITEM_DURATION = 30000
export const MIN_TIMELINE_ITEM_DURATION_PX = 10

export const MIN_TRANSITION_DURATION = 2500000
export const MAX_TRANSITION_DURATION = 50000000

export const restoringRecord = 'pendingRecord'
export const restoringRecordProjectId = 'pendingRecordProjectId'

export const LAYER_HEIGHT = 64
export const LAYER_BORDER = 4
export const LAYER_HEIGHT_WITH_BORDER = LAYER_HEIGHT + LAYER_BORDER // in pixels layer height + border
export const LAYER_ASSET_HEIGHT = 60
export const LAYER_CONTROL_WIDTH = 54


// CHROMA KEY
export const CHROMA_DEFAULT_KEY_COLOR = { r: 0, g: 255, b: 0, a: 1 }
export const CHROMA_DEFAULT_SIMILARITY = 400
export const CHROMA_DEFAULT_SMOOTHNESS = 80
export const CHROMA_DEFAULT_SPILL_REDUCTION = 100
export const CHROMA_DEFAULT_OPACITY = 100
export const CHROMA_MAX_OPACITY = 100

export const MAIN_BLOCK_HEIGHT = 'calc(100% - 52px)'
export const LEFT_MENU_WIDTH = 80
export const MEDIA_BLOCK_MIN_WIDTH = 214
export const MEDIA_RECORDING_BLOCK_MIN_WIDTH = 356
export const PREVIEW_CONTAINER_MIN_HEIGHT = 240
export const PREVIEW_MIN_WIDTH = 385
export const PREVIEW_I_O_POINTS_TRIM_HEIGHT = 18
export const SPLIT_PREVIEW_DEFAULT_WIDTH = '50%'
export const TIMELINE_LAYERS_DEFAULT_HEIGHT = 318
export const TIMELINE_MIN_WIDTH = 778
export const TIMELINE_MIN_WIDTH_WITHOUT_RESIZER = 766

export const SOURCE_MEDIA_ITEM_HEIGHT = 149

export const PREVIEW_SUMMARY_HEIGHT_INDENT = 9
export const PREVIEW_SUMMARY_EXTRA_HEIGHT_INDENT = 7
export const PREVIEW_CANVAS_ADDITIONAL_FREE_SPACE = 25
export const PREVIEW_SUMMARY_WIDTH_INDENT = 18

export const MAX_IMAGE_SIDE_SIZE = 10_000
export const MIN_IMAGE_SIDE_SIZE = 13

export const MAX_LOGO_SIZE_BYTES = 2_000_000
export const MAX_LOGO_SIZE_PX = 512

export const CATEGORIZED_HOTKEYS = {
  [HOTKEYS.UNDO]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.REDO]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.ZOOM_IN]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.ZOOM_OUT]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.PREV_FRAME]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.NEXT_FRAME]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.ADD_TO_TIMELINE]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.INCREASE_CLIP_VOLUME]: HOTKEY_CATEGORY.Tools,
  [HOTKEYS.DECREASE_CLIP_VOLUME]: HOTKEY_CATEGORY.Tools,
  [HOTKEYS.CUT_CLIPS]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.RIPPLE_DELETE]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.RIPPLE_TRIM_PREV]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.RIPPLE_TRIM_NEXT]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.SELECT_CLIPS_AT_CURRENT_POSITION]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.DELETE]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.PLAY_PAUSE]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.COPY]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.PASTE]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.PLAY]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.PAUSE]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.CLIP_SETTINGS]: HOTKEY_CATEGORY.Timeline,
  [HOTKEYS.MARK_IN]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.MARK_OUT]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.CLEAR_IN_OUT_MARK_POINTS]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.PLAY_TIMELINE_SEGMENT]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.SEEK_TO_IN_POINT]: HOTKEY_CATEGORY.Preview,
  [HOTKEYS.SEEK_TO_OUT_POINT]: HOTKEY_CATEGORY.Preview,
}

export const DEFAULT_PROFILE_ID = 'default'
export const DEFAULT_PROFILE_NAME = 'Default'
export const DEFAULT_PROJECT_OUTPUT_NAME = 'output'

export const EMPTY_STRING = ''
