import Cookies from './service'
import { defaultPlayerVolume } from '~/constant'
import { defaultCookiesParamsMaxTime } from './defaultCookiesParams'

const cookies = Cookies.getInstance()
const PLAYER_VOLUME = 'playerVolume'

export function set(playerVolume) {
  const currentValue = get()
  if (currentValue !== playerVolume) {
    cookies.set(PLAYER_VOLUME, playerVolume, defaultCookiesParamsMaxTime)
  }
}

export function get() {
  return Number(cookies.get(PLAYER_VOLUME) || defaultPlayerVolume)
}
