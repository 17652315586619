import Cookies from './service'
import { defaultCookiesParamsMaxTime } from './defaultCookiesParams'

const cookies = Cookies.getInstance()
const LATEST_PROJECT_ID = 'latestProjectId'

export function set(latestProjectId) {
  const currentValue = get()
  if (currentValue !== latestProjectId) {
    cookies.set(LATEST_PROJECT_ID, latestProjectId, defaultCookiesParamsMaxTime)
  }
}

export function get() {
  return cookies.get(LATEST_PROJECT_ID) || null
}
