/**
 * @memberOf ProjectData
 */
class Project {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, layers) {
    const { version, timeline, thumbnail, modifTime, reference, ioPoints } = jsonData
    const { sliderTime, scale, scrollLeft } = timeline

    this._version = version
    this._timeline = { sliderTime, scale, scrollLeft }
    this._layers = layers
    this._thumbnail = thumbnail
    this._modifTime = modifTime
    this._reference = reference
    this._ioPoints = ioPoints
  }

  /**
   * @returns {Object}
   */
  get reference() {
    return this._reference
  }

  /**
   * @returns {number}
   */
  get modifTime() {
    return this._modifTime
  }

  /**
   * @returns {string}
   */
  get version() {
    return this._version
  }

  /**
   * @returns {object}
   */
  get timeline() {
    return this._timeline
  }

  /**
   * @returns {ProjectData.ProjectLayer[]}
   */
  get layers() {
    return this._layers
  }

  /**
   * @returns {string}
   */
  get thumbnail() {
    return this._thumbnail
  }

  /**
   * @returns {object}
   */
  get ioPoints() {
    return this._ioPoints
  }

}

export default Project
