import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'
import { TIME_CODE_CONTAINER_LOCATION, TIME_CODE_STATE } from 'config/constants/timecode'
import { PLAYBACK_STATE } from '~/enums'

const initialState = {
  clipPlaybackState: PLAYBACK_STATE.STOP,
  timelinePlaybackState: PLAYBACK_STATE.STOP,
  timelineSegmentPlaybackState: PLAYBACK_STATE.STOP,
  selectedClipId: null,
  playingAsset: undefined,
  isBuffering: false,
  isMediaServerSeekProcessing: false,
  timeCodeState: TIME_CODE_STATE.VIEW,
  activeTimeCodeContainer: null,
  timeCodeMode: {
    [TIME_CODE_CONTAINER_LOCATION.TIMELINE]: null,
    [TIME_CODE_CONTAINER_LOCATION.PREVIEW]: null,
  },
  copiedTimeCode: {
    timeUnits: {},
    progress: 0,
  },
  memoSliderTime: { media: null, timeline: null },
}

const playback = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.CHANGE_PLAYBACK_STATE:
      setClipPlaybackState(state, action.payload.state)
      break
    case ActionTypes.CHANGE_PREVIEW_STATE:
      setTimelinePlaybackState(state, action.payload.state)
      break

    // TODO: remove setClipPlaybackState(state, PLAYBACK_STATE.PAUSE) after preview splitted finished (4032)
    case ActionTypes.TIMELINE_RECEIVED_FOCUS: {
      handleTimelineFocus(state)
      break
    }

    // TODO: remove setClipPlaybackState(state, PLAYBACK_STATE.PAUSE) after preview splitted finished (4032)
    case ActionTypes.ASSET_ADDED_TO_TIMELINE || ActionTypes.TRANSITION_ADDED_TO_TIMELINE: {
      // Playing source clip isn't resetted when *empty* timeline is hovered by dragged asset.
      // But if then we did drop that asset, timeline is empty no more, and playing clip must be resetted.
      handleTimelineFocus(state)
      break
    }

    case ActionTypes.DRAG_OVER_TIMELINE_STARTED:
      // TODO: remove setClipPlaybackState(state, PLAYBACK_STATE.PAUSE) after preview splitted finished (4032)
      setClipPlaybackState(state, PLAYBACK_STATE.PAUSE)
      setTimelinePlaybackState(state, PLAYBACK_STATE.PAUSE)
      break

    case ActionTypes.SET_PLAYBACK_MEDIA: {
      const { asset, playbackState } = action.payload
      setPlaybackClip(state, asset, playbackState)
      break
    }

    case ActionTypes.RESET_PLAYBACK_MEDIA: {
      state.selectedClipId = null
      state.playingAsset = null
      state.clipPlaybackState = PLAYBACK_STATE.STOP
      break
    }

    case ActionTypes.STOP_PLAYBACK_MEDIA: {
      setClipPlaybackState(state, PLAYBACK_STATE.PAUSE)
      break
    }

    case ActionTypes.STOP_PLAYBACK_TIMELINE: {
      setTimelinePlaybackState(state, PLAYBACK_STATE.PAUSE)
      break
    }

    case 'SET_PLAYBACK_BUFFERING': {
      const { isBuffering } = action.payload
      state.isBuffering = isBuffering
      break
    }

    case ActionTypes.SET_TIME_CODE_STATE: {
      const { timeCodeState, activeTimeCodeContainer } = action.payload
      state.timeCodeState = timeCodeState
      state.activeTimeCodeContainer = activeTimeCodeContainer ?? null
      break
    }

    case ActionTypes.SET_TIME_CODE_MODE: {
      const { mode, containerLocation } = action.payload
      localStorage.setItem(containerLocation, JSON.stringify(mode))
      const timeMode = localStorage.getItem(containerLocation)
      state.timeCodeMode[containerLocation] = JSON.parse(timeMode)
      break
    }

    case ActionTypes.SET_COPIED_TIME_CODE: {
      const { timeUnits, progress } = action.payload
      state.copiedTimeCode = {
        timeUnits,
        progress,
      }
      break
    }

    case ActionTypes.RESET_COPIED_TIME_CODE: {
      state.copiedTimeCode = {
        timeUnits: {},
        progress: 0,
      }
      break
    }
    case ActionTypes.SET_MEDIA_SERVER_SEEK_PROCESSING: {
      const { isProcessing } = action.payload
      state.isMediaServerSeekProcessing = isProcessing
      break
    }
    case ActionTypes.CHANGE_TIMELINE_SEGMENT_PLAYBACK_STATE: {
      const { status } = action.payload
      state.timelineSegmentPlaybackState = status
      break
    }
    case ActionTypes.SET_MEMO_SLIDER_TIME: {
      const { sliderTime, preview } = action.payload
      state.memoSliderTime[preview] = sliderTime
    } break
  }
}

export default produce(playback, initialState)

// ---
// TODO: remove setClipPlaybackState(state, PLAYBACK_STATE.PAUSE) after preview splitted finished (4032)
function handleTimelineFocus(state) {
  // Reset playback of media clip from media if timeline got active.
  setPlaybackClip(state, null)
}

function setClipPlaybackState(state, clipPlaybackState) {
  state.clipPlaybackState = clipPlaybackState

  /* if (state.clipPlaybackState === PLAYBACK_STATE.STOP) {
    state.selectedClipId = null
    state.playingAsset = undefined
  } */

  if (state.clipPlaybackState === PLAYBACK_STATE.PLAY) {
    state.timelinePlaybackState = PLAYBACK_STATE.PAUSE
  }
}

function setTimelinePlaybackState(state, timelinePlaybackState) {
  state.timelinePlaybackState = timelinePlaybackState
  if (state.timelinePlaybackState === PLAYBACK_STATE.PLAY) {
    state.clipPlaybackState = PLAYBACK_STATE.PAUSE
    // state.selectedClipId = null
    // state.playingAsset = undefined
  }
}

function setPlaybackClip(state, asset, playbackState) {
  if (asset === null) {
    // state.selectedClipId = null
    // state.playingAsset = undefined
    setClipPlaybackState(state, PLAYBACK_STATE.PAUSE)
  } else {
    state.selectedClipId = asset.id
    state.playingAsset = asset
    setClipPlaybackState(state, playbackState ?? PLAYBACK_STATE.PLAY)
  }
}
