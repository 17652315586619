import React, { MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { selectIsLoading } from 'selectors/projectData'
import classNames from 'classnames'
import { isNil, isNumber } from 'lodash'
import { Box } from '@material-ui/core'
import { PlayerType } from 'types/common'
import { ClipCreatorMarkType } from 'types/preview'
import { MARK_TYPE } from 'config/constants/preview'
import { ClipCreatorContextMenu } from './ClipCreatorContextMenu'
import './ClipCreator.scss'
import { LeftBound } from './LeftBound'
import { RightBound } from './RightBound'
import { CenterBar } from './CenterBar'

interface IProps {
  duration: number,
  preview: PlayerType,
  clipMarkers: Record<ClipCreatorMarkType, null | number>
  getTimePointToPercent(data: {
    markType: ClipCreatorMarkType,
    duration: number,
    time: number
  }): string
  onMarkIn(e: MouseEvent): void
  onMarkOut(e: MouseEvent): void
  onClearInOutPoints(e: MouseEvent): void
  contextMenuContainer: React.MutableRefObject<HTMLDivElement | null>
  onCloseMenu(e: MouseEvent): void
  anchorEl: HTMLDivElement | null,
  anchorPosition: {top: number, left: number},
  onClickMarkIcon: (markType: ClipCreatorMarkType, isResizer?: boolean) => (e: MouseEvent)=> void
  onSetDragging: (dragging: boolean) => (e: MouseEvent)=> void
  isMedia: boolean,
  showInOutPoints: boolean,
  isDragging: boolean,
  isResize: boolean,
  isLoading: boolean,
  isSplittedPreviewPlayers: boolean,
}

export const ClipCreatorBar = React.forwardRef<HTMLDivElement, IProps>(({
  duration,
  clipMarkers,
  getTimePointToPercent,
  onMarkIn,
  onMarkOut,
  onClearInOutPoints,
  onCloseMenu,
  anchorEl,
  anchorPosition,
  onClickMarkIcon,
  isMedia,
  showInOutPoints,
  onSetDragging,
  isDragging,
  isResize,
  isSplittedPreviewPlayers,
}, ref) => {
  const isLoading = useSelector(selectIsLoading)
  const { markIn, markOut } = clipMarkers ?? {}
  const left = markIn ? getTimePointToPercent({ markType: MARK_TYPE.IN, duration, time: markIn }) : 0
  const right = markOut ? getTimePointToPercent({ markType: MARK_TYPE.OUT, duration, time: markOut }) : 0
  const MIN_PERCENT_WIDTH = 5
  const isSm = (100 - (parseInt(String(right)) + parseInt(String(left)))) < MIN_PERCENT_WIDTH
  const hiddenDragButton = !isNumber(markIn) || !isNumber(markOut)

  if (!duration || isLoading || document.fullscreenElement) return null

  return (
    <>
      {showInOutPoints && !(isNil(markIn) && isNil(markOut)) && (
        <Box className={classNames('clip-creator', { 'split-preview': isSplittedPreviewPlayers })}>
          <div className="clip-creator__container" style={{ left, right }}>
            <div className={classNames('clip-creator__bar', { sm: isSm })}>
              <div className={classNames('clip-creator__background', { sm: isSm })} />
              <div className="clip-creator__control" ref={ref}>
                <LeftBound markIn={markIn} onClickMarkIcon={onClickMarkIcon} isDragging={isDragging} />
                <CenterBar
                  onSetDragging={onSetDragging}
                  isDragging={isDragging}
                  hiddenDragButton={hiddenDragButton}
                  isResize={isResize}
                  isSm={isSm}
                />
                <RightBound markOut={markOut} onClickMarkIcon={onClickMarkIcon} isDragging={isDragging} />
              </div>
            </div>
          </div>
        </Box>
      )}
      <ClipCreatorContextMenu
        anchorEl={anchorEl}
        onMarkIn={onMarkIn}
        onMarkOut={onMarkOut}
        onClearInOutPoints={onClearInOutPoints}
        onCloseMenu={onCloseMenu}
        anchorPosition={anchorPosition}
        clipMarkers={clipMarkers}
        isMedia={isMedia}
      />
    </>
  )
})
