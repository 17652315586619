import { patchAsset } from 'actions/layer'
import { setActivePreview, setInOutPointsTimelineAsset, setShowInOutPoints, resetMarkers, setSourcePlayerProgress, setPreviewMarkers } from 'actions/preview'
import { MARK_TYPE, PREVIEW_MODE } from 'config/constants/preview'
import { PLAYER_TYPE } from 'enums'
import { isEmpty } from 'lodash'
import { all, takeEvery, put, select, takeLatest, delay } from 'redux-saga/effects'
import { selectAssetById, selectSliderTime } from 'selectors'
import { secondsToTimelineTime, timelineTimeToSeconds } from '~/Util'
import * as ActionTypes from '~/actions/ActionTypes'
import { saveProject, saveTimelineMarkersAsProjectIOPoints } from '~/actions/projectData/saveProject'

function* resetInOutPointsAsset({ type, payload }) {
  if ([ ActionTypes.DELETE_ASSETS, ActionTypes.RIPPLE_DELETE_ASSETS ].includes(type)) {
    const { assets } = payload
    if (!isEmpty(assets)) {
      const ioPointAssetID = yield select(state => state.preview.inOutPointsTimeLineAsset)
      const removedIOPointsAsset = assets.find(asset => asset.id === ioPointAssetID)
      if (removedIOPointsAsset) {
        yield put(setInOutPointsTimelineAsset(null))
        yield put(setShowInOutPoints(false, PLAYER_TYPE.MEDIA))
        yield put(resetMarkers({ preview: PLAYER_TYPE.MEDIA }))
        yield put(setSourcePlayerProgress(0))
      }
    }
  } else if ([ ActionTypes.SET_PLAYBACK_MEDIA ].includes(type)) {
    yield put(setInOutPointsTimelineAsset(null))
    yield put(resetMarkers({ preview: PLAYER_TYPE.MEDIA }))
    const { resetPreviewProgress, asset } = payload ?? {}
    if (resetPreviewProgress) {
      yield put(setSourcePlayerProgress(0))
    }

    let ioPoints = null
    if (asset?.id) {
      ioPoints = sessionStorage.getItem(asset.id)
      if (ioPoints) {
        const data = JSON.parse(ioPoints)
        yield put(setPreviewMarkers({
          [PLAYER_TYPE.MEDIA]: {
            [MARK_TYPE.IN]: data.markIn,
            [MARK_TYPE.OUT]: data.markOut,
          },
        }, { preview: PLAYER_TYPE.MEDIA }))
      }
    } else {
      yield put(setShowInOutPoints(!!ioPoints, PLAYER_TYPE.MEDIA))
    }
  }
}

function* resetPreviewIOPoints({ type }) {
  if (type === ActionTypes.DELETE_IO_POINTS_TIMELINE_FRAGMENT) {
    yield put(resetMarkers({ preview: PLAYER_TYPE.TIMELINE }))
    yield put(setShowInOutPoints(false, PLAYER_TYPE.TIMELINE))
  }
}

function* switchActivePreview({ type, payload }) {
  const isSplittedPreview = yield select(state => state.preview.splitPlayers)
  const activePreview = yield select(state => state.preview.activePreview)
  const activePreviewMode = yield select(state => state.preview.mode)

  switch (type) {
    case ActionTypes.SET_IS_MOVING_SLIDER:
    case ActionTypes.ASSET_ADDED_TO_TIMELINE:
    case ActionTypes.ASSET_SELECTED: {
      if (activePreviewMode === PREVIEW_MODE.AUTO || isSplittedPreview) {
        if (activePreview !== PLAYER_TYPE.TIMELINE) {
          yield put(setActivePreview(PLAYER_TYPE.TIMELINE))
        }
      }
      break
    }
    case ActionTypes.SET_PLAYBACK_MEDIA:
      if (isSplittedPreview || (activePreviewMode === PREVIEW_MODE.AUTO)) {
        if (activePreview !== PLAYER_TYPE.MEDIA) {
          yield put(setActivePreview(PLAYER_TYPE.MEDIA))
        }
      }
      break
    case ActionTypes.SET_PREVIEW_MARKER:
    case ActionTypes.SET_PREVIEW_MARKERS: {
      const { preview } = payload ?? {}
      if (isSplittedPreview || (activePreviewMode === PREVIEW_MODE.AUTO)) {
        if (activePreview !== preview) {
          yield put(setActivePreview(preview))
        }
      }
    } break
    default:
      break
  }
}

function* patchAssetWithIOPoints() {
  const progress = yield select(state => state.preview.playerProgress.source)
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const asset = yield select(state => selectAssetById(state, inOutPointsTimeLineAssetId))
  if (asset) {
    yield put(patchAsset(asset, {
      playerProgress: secondsToTimelineTime(progress),
    }, { historyAction: false }))
  }
}

function* updatePreviewMarkers({ payload }) {
  const { assetId } = payload
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const sliderTime = yield select(selectSliderTime)
  if (assetId === inOutPointsTimeLineAssetId) {
    const asset = yield select(state => selectAssetById(state, assetId))
    const { mediaStart, duration, mediaFileDuration } = asset ?? {}

    yield put(setPreviewMarkers({
      [PLAYER_TYPE.MEDIA]: {
        [MARK_TYPE.IN]: !mediaStart ? 0 : timelineTimeToSeconds(mediaStart),
        [MARK_TYPE.OUT]: duration === mediaFileDuration
          ? timelineTimeToSeconds(duration)
          : timelineTimeToSeconds(duration + (mediaStart ?? 0)),
      },
    }, { preview: PLAYER_TYPE.MEDIA, updateAsset: false }))

    // Set media player progress depending on slidertime position (for doubleClick inOutPointsTimeLineAsset)
    if (asset.startTime <= sliderTime && asset.endTime >= sliderTime) {
      const playerProgress = sliderTime - asset.startTime + asset.mediaStart
      yield put(setSourcePlayerProgress(timelineTimeToSeconds(playerProgress)))
    }
  }
}

function* updatePreview() {
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const asset = yield select(state => selectAssetById(state, inOutPointsTimeLineAssetId))
  if (asset) {
    const payload = { assetId: asset.id }
    yield updatePreviewMarkers({ payload })
  }
}

// Saving project with debounce effect
function* onSaveProject() {
  yield delay(500)
  const timelineMarkers = yield select(state => state.preview.clipCreator.timeline)
  yield put(saveTimelineMarkersAsProjectIOPoints(timelineMarkers))
  yield put(saveProject())
}

function* watchAll() {
  yield all([
    takeEvery([
      ActionTypes.SET_PLAYBACK_MEDIA,
      ActionTypes.ASSET_SELECTED,
      ActionTypes.ASSET_ADDED_TO_TIMELINE,
      ActionTypes.SET_IS_MOVING_SLIDER,
      ActionTypes.SET_PREVIEW_MARKER,
      ActionTypes.SET_PREVIEW_MARKERS,
    ], switchActivePreview),
    takeEvery([
      ActionTypes.DELETE_IO_POINTS_TIMELINE_FRAGMENT,
    ], resetPreviewIOPoints),
    takeEvery([
      ActionTypes.SET_PLAYBACK_MEDIA,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
    ], resetInOutPointsAsset),
    takeLatest([
      ActionTypes.SET_SOURCE_PLAYER_PROGRESS,
    ], patchAssetWithIOPoints),
    takeEvery(ActionTypes.UPDATE_PREVIEW_MARKERS, updatePreviewMarkers),
    takeLatest([
      ActionTypes.RIPPLE_TRIM,
      ActionTypes.UNDO_RIPPLE_TRIM,
      ActionTypes.UNDO_SPLITTING_ASSETS,
      ActionTypes.SPLIT,
    ], updatePreview),
    takeLatest([
      ActionTypes.SET_PREVIEW_MARKER,
      ActionTypes.SET_PREVIEW_MARKERS,
      ActionTypes.RESET_MARK,
    ], onSaveProject),
  ])
}

export default watchAll
