import { DRAGNDROP_TYPE, DROP_TARGET_TYPE, SOURCE_FILE_TYPES } from 'enums'
import { useDrop, ConnectDropTarget } from 'react-dnd'
import { useAction } from 'hooks'
import * as Actions from 'actions'
import { setDndDropTarget } from 'actions/mainView'

type Item = {
  type: string,
  id: string,
  fileExtension: string,
  incompatibleFileExtensions: string
}
type Group = { type: 'group', selectedItems: Array<Item>}

function useFolderDropTarget(id: string): [{ item: unknown }, ConnectDropTarget] {
  const onMoveMediaItemsToFolder = useAction(Actions.sourceFiles.moveMediaItemsToFolder)
  const onSetDndDropTarget = useAction(setDndDropTarget)
  const [ collectedProps, drop ] = useDrop({
    accept: [ DRAGNDROP_TYPE.MEDIA_ITEM,
      DRAGNDROP_TYPE.IMAGE_ITEM,
      DRAGNDROP_TYPE.AUDIO_ITEM,
      DRAGNDROP_TYPE.FOLDER,
      DRAGNDROP_TYPE.GROUP ],
    drop: (item: Item | Group, monitor) => {
      const isFolder = item.type === DRAGNDROP_TYPE.FOLDER
      const isGroup = item.type === DRAGNDROP_TYPE.GROUP
      onSetDndDropTarget(DROP_TARGET_TYPE.ROOT)

      if (isGroup) { return { id } }

      if (!('selectedItems' in item)) {
        onMoveMediaItemsToFolder({ folders: isFolder ? [ item.id ] : [],
          files: isFolder ? [] : [ item.id ],
          targetFolder: id,
          type: SOURCE_FILE_TYPES.MEDIA })
      }
      return { id }
    },
    canDrop: (item: Item | Group, monitor) => {
      if (!__CFG__.SOURCE_FILES_MANAGEMENT.MOVE_TO_FOLDERS) {
        return false
      }
      if (item.type === DRAGNDROP_TYPE.GROUP && 'selectedItems' in item) {
        return !item.selectedItems.map(item => item.id).includes(id)
      }
      if (!('selectedItems' in item)) { return item.id !== id }
      return false
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  })

  return [ collectedProps, drop ]
}

export default useFolderDropTarget
