import * as ActionTypes from 'actions/ActionTypes'
import produce from 'immer'
import * as ShowBlankProjectInList from '~/cookieServices/ShowBlankProjectInList'

const initialState = {
  isLoading: false,
  searchValue: '',
  projects: [],
  activeId: null,
  error: null,
  activeProjectLoading: false,
  activeProjectSaving: false,
  activeProjectId: undefined,
  activeProjectName: undefined,
  activeProject: undefined,
  isActiveProjectDeleted: false,
  saveEmpty: false,
  showBlankProjectInList: ShowBlankProjectInList.get(),
  renamingProjectIdInList: undefined,
  totalCount: undefined,
  activeProjectError: undefined,
  latestSavedTime: undefined,
}

function projectsDataReducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.PROJECTS_ALLOW_SAVE_EMPTY: {
      state.saveEmpty = !!action.payload?.saveEmpty
      break
    }
    case ActionTypes.PROJECTS_SET_LATEST_SAVED_TIME: {
      state.latestSavedTime = Date.now()
      break
    }
    case ActionTypes.PROJECTS_SHOW_BLANK_IN_LIST: {
      state.showBlankProjectInList = true
      break
    }
    case ActionTypes.PROJECTS_HIDE_BLANK_IN_LIST: {
      state.showBlankProjectInList = false
      break
    }
    case ActionTypes.PROJECTS_SET_SEARCH_VALUE: {
      if (state.searchValue !== action.payload.searchValue) {
        state.searchValue = action.payload.searchValue
        state.projects = []
      }
      break
    }
    case ActionTypes.PROJECTS_REQUESTED: {
      state.isLoading = true
      break
    }
    case ActionTypes.PROJECTS_START_PROJECT_LOAING: {
      state.activeProjectLoading = true
      break
    }
    case ActionTypes.PROJECTS_ACTIVE_PROJECT_ERROR: {
      state.activeProjectError = action.payload
      break
    }
    case ActionTypes.PROJECTS_CLEAR_ERROR: {
      state.activeProjectError = undefined
      state.error = null
      break
    }
    case ActionTypes.PROJECTS_END_PROJECT_LOAING: {
      state.activeProjectLoading = false
      break
    }
    case ActionTypes.PROJECTS_ACTIVATE: {
      state.activeProjectId = action.payload?.id
      state.activeProjectName = action.payload?.name
      state.isActiveProjectDeleted = false
      state.activeProject = action.payload?.activeProject
      break
    }
    case ActionTypes.PROJECTS_SET_ACTIVE_NAME: {
      state.activeProjectName = action.payload?.name
      break
    }
    case ActionTypes.PROJECT_SAVE_IO_POINTS: {
      const { ioPoints } = action.payload
      if (state.activeProject) {
        state.activeProject.ioPoints = ioPoints
      }
      break
    }
    case ActionTypes.PROJECTS_DELETE: {
      const { id } = action.payload
      state.projects = state.projects.filter(project => project.id !== id)
      if (state.activeProjectId === id) {
        state.activeProjectId = undefined
        state.isActiveProjectDeleted = true
      }
      break
    }
    case ActionTypes.PROJECTS_RENAME: {
      const renamingProject = state.projects
        .find(p => p.id === action.payload.id)
      if (renamingProject) {
        renamingProject.name = action.payload.name
      }

      if (action.payload.id === state.activeProjectId) {
        state.activeProjectName = action.payload.name
      }
      break
    }
    case ActionTypes.PROJECTS_SET_RENAMING_ID_IN_LIST: {
      state.renamingProjectIdInList = action.payload
      break
    }
    case ActionTypes.PROJECTS_REFRESH_THUMBNAIL: {
      const { thumbnail, id } = action.payload
      const changingProject = state.projects
        .find(p => p.id === id)
      if (changingProject) {
        changingProject.thumbnail = thumbnail
        changingProject.modifTime = Date.now()
      }
      if (state.activeProject) {
        state.activeProject.thumbnail = thumbnail
      }
      break
    }
    case ActionTypes.PROJECTS_CREATE: {
      const { project, isInitialRenaming } = action.payload
      state.projects.unshift(project)

      if (isInitialRenaming !== undefined) {
        state.renamingProjectIdInList = project.id
      }
      break
    }
    case ActionTypes.PROJECTS_LOADED: {
      state.isLoading = false
      if (action.error) {
        state.error = action.error
      } else {
        state.projects = action.payload.projects
        state.totalCount = action.payload.totalCount

        // NOTE: refresh thumbnail in list if active project saved before fetching list (http://18.184.210.86/issues/1180)
        if (state.activeProject?.thumbnail) {
          const activeProject = state.projects.find(p => p.id === state.activeProjectId)
          if (activeProject && activeProject.thumbnail !== state.activeProject?.thumbnail) {
            activeProject.thumbnail = state.activeProject?.thumbnail
          }
        }
      }
      break
    }
    case ActionTypes.PROJECTS_START_SAVING: {
      state.activeProjectSaving = true
      break
    }
    case ActionTypes.PROJECTS_END_SAVING: {
      state.activeProjectSaving = false
      break
    }
  }
}

export default produce(projectsDataReducer, initialState)
