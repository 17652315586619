import classNames from 'classnames'
import { TranslationContext } from 'contexts/TranslationContext'
import { useEditorActions } from 'hooks/useEditorActions'
import React, { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppRoutes } from '~/AppRoutes'
import * as Actions from '~/actions'
import { DeleteProjectConfirmation } from '~/components/Projects/components/DeleteProjectConfirmation/DeleteProjectConfirmation'
import { ProjectSaveAsDialog } from '~/components/Projects/components/ProjectSaveAsDialog/ProjectSaveAsDialog'
import { DropdownMenu } from '~/components/base/DropdownMenu/DropdownMenu'
import { InputRename } from '~/components/base/InputRename/InputRename'
import * as LatestProjectName from '~/cookieServices/LatestProjectName'
import { useAction, useModalDialog } from '~/hooks'
import { selectActiveProjectId } from '~/selectors/projectData'
import Profile from './Profile'
import Title from './Title'
import './default.scss'
import { useNavigationTabs } from './useNavigationTabs'

const NAME_MAX_VISIBLE_LETTERS = 18
const NAME_LETTER_WIDTH = 16

export default function WebEditorHeader() {
  const inputRef = useRef(null)
  const activeProjectId = useSelector(state => selectActiveProjectId(state))

  const renameProject = useAction(Actions.renameProject.renameProject, activeProjectId)
  const deleteProject = useAction(Actions.deleteProject.deleteProject, activeProjectId)
  const clearEditor = useAction(Actions.clearEditor.clearEditor)

  const history = useHistory()
  const location = useLocation()

  const { activeProjectName, saveAsDialog } = useEditorActions()

  const navigationTabs = useNavigationTabs()

  const isProjectsTabActive = location.pathname === AppRoutes.Projects

  const deletionDialog = useModalDialog({
    onConfirm: async () => {
      // NOTE: it's important to prevent autosaving when component will unmount
      clearEditor()
      await deleteProject()
      history.push(AppRoutes.Projects)
    },
  })

  const startRenaming = () => {
    setTimeout(() => {
      inputRef.current.click()
    })
  }

  const { t } = useContext(TranslationContext)

  const projectMenuItems = [
    {
      text: t('HEADER_MENU_LIST_ITEM_RENAME'),
      onClick: startRenaming,
    },
    {
      text: t('HEADER_MENU_LIST_ITEM_DELETE'),
      onClick: deletionDialog.open,
    },
    {
      divider: true,
    },
    {
      text: t('HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS'),
      onClick: saveAsDialog.open,
    },
  ]

  return (
    <header className={classNames('header-solveig', {
      'no-title': !__CFG__.HEADER.TITLE_VISIBLE,
    })}
    >
      <Title />
      <If condition={!isProjectsTabActive}>
        <DropdownMenu menuItems={projectMenuItems}>
          <InputRename
            className="project-rename"
            ref={inputRef}
            activeName={activeProjectName || LatestProjectName.get()}
            maxVisibleLetter={NAME_MAX_VISIBLE_LETTERS}
            letterWidth={NAME_LETTER_WIDTH}
            onRename={renameProject}
          />
        </DropdownMenu>
      </If>

      <DeleteProjectConfirmation modalDialog={deletionDialog} name={activeProjectName} />
      <ProjectSaveAsDialog modalDialog={saveAsDialog} projectName={activeProjectName} />

      <div className="header__nav">
        <div className="header__nav__tabs">
          {navigationTabs.filter(tab => !tab.disappear).map(tab => (
            <div
              key={tab.url}
              onClick={() => tab.onClick()}
              className={classNames('header__nav__tabs__tab', { 'header__nav__tabs__tab--active': tab.isActive })}
              data-lang-id={tab.dataLangId}
              title={tab.title}
            >
              {tab.icon}
              {tab.text}
            </div>
          ))}
        </div>
        <Profile />
      </div>
    </header>
  )
}
