/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
import { setReference } from 'actions/project/editingTask'
import compareVersions from 'compare-versions'
import { partition } from 'lodash'

import * as ActionTypes from '~/actions/ActionTypes'
import { activateProject } from '~/actions/projectData/activateProject'
import { startProjectLoading } from '~/actions/projectData/startProjectLoading'
import { rewind } from '~/actions/timeline'
import { newProjectId, newProjectName, PROJECT_BACK_COMPATIBILITY_BEFORE } from '~/constant'
import { TRANSITIONS } from '~/enums'
import ProjectTransitionAsset from '~/models/ProjectData/ProjectTransitionAsset'
import * as API from '~/ServerAPI'
import { clearEditor } from './clearEditor'
import { setActiveProjectError } from './setActiveProjectError'
import { getProject } from './mappings/getAssetMapping'
import { prepareProjectAsset } from '~/helpers/assets/prepareProjectAsset'
import { ERROR_TYPE } from '~/errors/ErrorType'
import { setProjectIOPoints } from '~/actions/preview'

const correctAssets = assets => {
  const [ assetsWithoutTransitions, transitions ] = partition(assets,
    a => !(a instanceof ProjectTransitionAsset))
  const correctedAssets = [ ...assets ]
  assetsWithoutTransitions.forEach((asset, i, array) => {
    const prevAsset = array[i - 1]
    const prevAssetEndTime = prevAsset
      ? prevAsset.startTime + prevAsset.duration
      : 0
    if (asset.startTime < prevAssetEndTime) {
      asset.startTime = prevAssetEndTime
    }
    const transitionWithRightAsset = transitions.find(t => t.rightVideoAssetId === asset.id)
    if (transitionWithRightAsset?.type === TRANSITIONS.DISSOLVE) {
      asset.startTime -= transitionWithRightAsset.duration
    }
  })
  transitions.forEach(t => {
    const transitionAssetLeft = assetsWithoutTransitions.find(a => a.id === t.leftVideoAssetId)
    const transitionAssetRight = assetsWithoutTransitions.find(
      a => a.id === t.rightVideoAssetId && t.type === TRANSITIONS.FADEIN
    )
    if (transitionAssetLeft) {
      t.startTime = transitionAssetLeft.startTime + transitionAssetLeft.duration - t.duration
    } else if (transitionAssetRight) {
      t.startTime = transitionAssetRight.startTime
    }
  })
  return correctedAssets
}

export const loadProject = projectId => async dispatch => {
  await dispatch(clearEditor())
  await dispatch(startProjectLoading())

  if (projectId && projectId !== newProjectId) {
    try {
      const response = await API.getProject(projectId)

      const projectData = JSON.parse(response.data)
      const project = getProject(typeof response.data === 'string' ? projectData : response.data)
      const { name: projectName } = response
      const { version,
        createTime: projectDataCreateTime } = projectData
      // http://18.184.210.86/issues/2095
      const actualCreateTime = projectDataCreateTime || response.createTime
      await dispatch(activateProject({
        id: projectId,
        name: projectName,
        activeProject: { ...projectData, createTime: actualCreateTime },
      }))

      const { layers, timeline, reference } = project
      const { scale, sliderTime, scrollLeft } = timeline

      // OLD VERSION BACKWARD COMPATIBILITY
      if (compareVersions(version, PROJECT_BACK_COMPATIBILITY_BEFORE) <= 0) {
        layers.forEach(l => {
          const assetsSortedByStartTime = l.assets
            .sort(
              (a, b) => b.startTime - a.startTime
            )
            .sort(a => a instanceof ProjectTransitionAsset ? 1 : -1)

          correctAssets(assetsSortedByStartTime)
        })
      }

      const assets = layers.reduce((acc, l) => ([ ...acc, ...l.assets ]), [])
      // TODO: refactor this sync loop
      for (let i = 0; i < assets.length; i++) {
        await prepareProjectAsset(assets[i])
      }
      await dispatch({ type: ActionTypes.LOAD_PROJECT,
        payload: {
          layers,
          assets,
          scale,
          sliderTime,
          scrollLeft,
        } })

      if (reference) {
        dispatch(setReference(reference))
      }

      // This is useful for correct initialize preview players
      await dispatch(rewind(project.timeline.sliderTime))
      await dispatch({
        type: ActionTypes.LAYER_ASSETS_REHYDRATE,
        payload: projectData,
      })
    } catch (e) {
      console.error(e)
      dispatch(setActiveProjectError(ERROR_TYPE.PROJECT_LOAD_FAILED, `Cannot load project: ${e.message || e}`))
    }
  } else {
    await dispatch(activateProject({ id: newProjectId, name: newProjectName }))
  }

  await dispatch(setProjectIOPoints())
  // NOTE: this detects in Layer when all assets loaded
  // await dispatch(endProjectLoading())
}
