import { updateTask, clearTask } from 'actions/nabletHld/nabletHld'
import { hideBlankProjectFromList } from 'actions/projectData/hideBlankProjectFromList'
import { createHldTask, deleteHldTask, getHldTaskStatus, createProject } from '~/ServerAPI'
import { TASK_STATUS } from '~/enums'


const CHECK_STATUS_INTERVAL = 500
async function checkStatusLongPolling(dispatch, taskId) {
  try {
    const data = await getHldTaskStatus(taskId)
    const { status, progress, error, cutLists } = data
    if (error) {
      dispatch(updateTask({
        taskId,
        error: error.message,
        status,
      }))
      return
    }

    dispatch(updateTask({ taskId, status, progress, cutLists }))
    if (status !== TASK_STATUS.COMPLETED && status !== TASK_STATUS.ABORTED) {
      setTimeout(checkStatusLongPolling, CHECK_STATUS_INTERVAL, dispatch, taskId)
    }
  } catch (error) {
    dispatch(updateTask({
      taskId,
      status: TASK_STATUS.ABORTED,
      error: {
        message: error.toString(),
      },
    }))
  }
}

export const requestCreateTask = (fileId,
  onnxName, targetDurationSec) => async dispatch => {
  try {
    const response = await createHldTask({ fileId, onnxName, targetDurationSec })
    const taskId = response?.data?.id
    if (!taskId) {
      throw Error('taskId is empty')
    }
    setTimeout(checkStatusLongPolling, 0, dispatch, taskId)
  } catch (e) {
    const error = {
      message: e.data?.error || e.data?.message || e.toString(),
    }
    dispatch(updateTask({ status: TASK_STATUS.ABORTED, error }))
  }
}

export const requestDeleteTask = taskId => async dispatch => {
  try {
    await deleteHldTask(taskId)
    dispatch(clearTask())
  } catch (e) {
    console.error(`requestDeleteTask ${e}`)
  }
}

export const requestCreateProjects = (projectName, fileId, cutLists) => async dispatch => {
  try {
    await Promise.all(cutLists
      .filter(item => item.intervals.length > 0)
      .filter(item => item.label !== 'common')
      .map(({ label, intervals }) => {
        const projectParams = {
          name: `${projectName}_${label}`,
          fileId,
          intervals,
        }
        return createProject(projectParams)
      }))
    const commonList = cutLists.find(item => item.label === 'common')
    const projectParams = {
      name: `${projectName}_${commonList.label}`,
      fileId,
      intervals: commonList.intervals,
    }
    const { id } = await createProject(projectParams)
    await dispatch(hideBlankProjectFromList())
    return id
    // window.location.pathname = `/projects/${id}`
  } catch (e) {
    console.error(`requestCreateProject ${e}`)
    return null
  }
}
