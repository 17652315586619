import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAssets } from 'selectors'
import { secondsToTimelineTime } from 'Util'
import { selectInOutPointsMoving, selectorActivePreview, selectorPreviewTimelineIOPoints } from 'selectors/preview'
import { isNil, isNumber } from 'lodash'
import { useAction } from 'hooks/utils'
import { HOTKEYS, PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import {
  changeTimelinePlaybackState,
  setMemoSliderTime,
  setTimelineSegmentPlaybackState,
  stopPlaybackTimeline
} from 'actions/playback'
import { selectMemoSliderTime, selectTimelineSegmentPlaybackState } from 'selectors/playback'
import { rewind } from 'actions/timeline'
import { PlayerType } from 'types/common'

export const usePlayingTimelineFragment = ({ preview }: { preview: PlayerType }) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (preview !== PLAYER_TYPE.TIMELINE) {
    return
  }
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const sliderTime = useSelector((state: RootState) => state.timeline.sliderTime)
  const { timeline: memoSliderTime } = useSelector(selectMemoSliderTime)
  const timelineIOPoints = useSelector(selectorPreviewTimelineIOPoints)
  const assets = useSelector(getAssets)
  const timelineSegmentPlaybackState = useSelector(selectTimelineSegmentPlaybackState)
  const isMovingIOPoints = useSelector(selectInOutPointsMoving)
  const activePreview = useSelector(selectorActivePreview)

  const onStopPlaybackTimeline = useAction(stopPlaybackTimeline)
  const onChangeTimelinePlaybackState = useAction(changeTimelinePlaybackState)
  const onSetTimelineSegmentPlaybackState = useAction(setTimelineSegmentPlaybackState)
  const onRewind = useAction(rewind)
  const onSetMemoSliderTime = useAction(setMemoSliderTime)

  const endTime = assets[assets?.length - 1]?.endTime || null
  const { markIn, markOut } = timelineIOPoints

  const play = useCallback((markIn: number) => {
    if (activePreview === PLAYER_TYPE.TIMELINE) {
      if (endTime && (secondsToTimelineTime(markIn) < endTime)) {
        onStopPlaybackTimeline()
        onRewind(secondsToTimelineTime(markIn))
        onSetTimelineSegmentPlaybackState({ status: PLAYBACK_STATE.PLAY })
        onChangeTimelinePlaybackState(PLAYBACK_STATE.PLAY)
        onSetMemoSliderTime(sliderTime, PLAYER_TYPE.TIMELINE)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sliderTime, endTime, activePreview ])

  const stop = useCallback(() => {
    if (timelineSegmentPlaybackState === PLAYBACK_STATE.PLAY && !isMovingIOPoints) {
      onStopPlaybackTimeline()
      onSetTimelineSegmentPlaybackState({ status: PLAYBACK_STATE.STOP })
      if (!isNil(memoSliderTime)) {
        onRewind(memoSliderTime)
      }
      onSetMemoSliderTime(null, PLAYER_TYPE.TIMELINE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isMovingIOPoints, memoSliderTime ])

  const playTimelineSegment = useCallback(() => {
    if (activePreview === PLAYER_TYPE.TIMELINE) {
      const { markIn } = timelineIOPoints
      isNil(memoSliderTime) ? play(markIn ?? 0) : stop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ timelineIOPoints, memoSliderTime, play, stop, activePreview ])

  const seek = useCallback((secPoint: number | null) => {
    if (activePreview === PLAYER_TYPE.TIMELINE) {
      if (isNumber(secPoint)) {
        onRewind(secondsToTimelineTime(secPoint))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const seekIn = useCallback(() => seek(markIn), [ seek, markIn ])
  const seekOut = useCallback(() => seek(markOut), [ seek, markOut ])

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PLAY_TIMELINE_SEGMENT], playTimelineSegment)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.SEEK_TO_IN_POINT], seekIn)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.SEEK_TO_OUT_POINT], seekOut)

  useEffect(() => {
    if (activePreview === PLAYER_TYPE.TIMELINE) {
      if (!isNil(memoSliderTime) && !isMovingIOPoints) {
        const { markOut } = timelineIOPoints
        const markOutTimelineTime = secondsToTimelineTime(markOut)
        const maxTime = (!markOutTimelineTime || markOutTimelineTime > endTime)
          ? endTime
          : markOutTimelineTime
        if (sliderTime >= maxTime) stop()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activePreview, sliderTime, timelineIOPoints, memoSliderTime, stop, endTime, isMovingIOPoints ])
}
