import Cookies from './service'
import { defaultCookiesParamsMaxTime } from './defaultCookiesParams'

const cookies = Cookies.getInstance()
const SHOW_BLANK_PROJECT_IN_LIST = 'showBlankProjectInList'

export function set(value) {
  cookies.set(SHOW_BLANK_PROJECT_IN_LIST, Number(value), defaultCookiesParamsMaxTime)
}

export function get() {
  return Boolean(Number(cookies.get(SHOW_BLANK_PROJECT_IN_LIST)))
}
