import React, { memo } from 'react'
import { isNil } from 'lodash'
import Scrollbars from 'react-custom-scrollbars-2'

import { useIOPointsTimeline } from 'hooks/timeline/iopoints/useIOPointsTimeline'
import { Point } from './components/Point'
import { Line } from './components/Line'
import './IOPointsSlider.scss'

export const IOPointsSlider = memo(({ scale, getTimeFromMouseEvent, scrollbarRef }: {
  scale: number, getTimeFromMouseEvent(e: MouseEvent): void,
  scrollbarRef: React.MutableRefObject<Scrollbars>
}) => {
  const {
    leftPointRef,
    rightPointRef,
    leftPoint,
    rightPoint,
    translateLeftX,
    translateRightX,
    countLayers,
    scrollLeft,
    isSelectedAsset,
  } = useIOPointsTimeline({ scale, getTimeFromMouseEvent, scrollbarRef })
  if (isNil(rightPoint) && isNil(leftPoint)) return null
  return (
    <div className="io-points-slider">
      <Point
        ref={leftPointRef}
        point={leftPoint}
        scale={scale}
        scrollLeft={scrollLeft}
        countLayers={countLayers}
        isSelectedAsset={isSelectedAsset}
        isLeft
      />
      <Line
        translateRightX={translateRightX}
        translateLeftX={translateLeftX}
      />
      <Point
        ref={rightPointRef}
        point={rightPoint}
        scale={scale}
        scrollLeft={scrollLeft}
        countLayers={countLayers}
        isSelectedAsset={isSelectedAsset}
      />
    </div>
  )
})
