import cx from 'classnames'
import { Box } from '@material-ui/core'
import { isNil } from 'lodash'

import * as Actions from 'actions'
import MediaRecordingCombobox from 'components/Timeline/components/MediaRecordingCombobox'
import RawButton from 'components/base/RawButton'
import { HOTKEYS } from 'enums'
import React, { memo, useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import * as Selectors from 'selectors'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { rippleDelete as rippleDeleteAction } from 'actions/timeline'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { TIME_CODE_CONTAINER_LOCATION, TIME_CODE_MODE } from 'config/constants/timecode'
import { addSourceFileAtSliderPosition } from '~/actions/addSourceFileAtSliderPosition'
import { historyRedo as historyRedoAction, historyUndo as historyUndoAction } from '~/actions/historyActions'
import { assetsPaste } from '~/actions/assetsPaste'
import { ReactComponent as CheckIcon } from '~/assets/timeline/ic_check.svg'
import { ReactComponent as CutIcon } from '~/assets/timeline/ic_cut.svg'
import { ReactComponent as NextIcon } from '~/assets/timeline/ic_redo.svg'
import { ReactComponent as SettingsIcon } from '~/assets/timeline/ic_settings.svg'
import { ReactComponent as PrevIcon } from '~/assets/timeline/ic_undo.svg'
import { ReactComponent as CopyIcon } from '~/assets/timeline/ic_copy.svg'
import { ReactComponent as PasteIcon } from '~/assets/timeline/ic_paste.svg'
import { TimeCode } from '~/components/Common/TimeCode'
import { useAction } from '~/hooks'
import { PlaceholderAsset } from '~/models/Asset'
import { selectIsPointerAtFirstAction, selectIsPointerAtLastAction } from '~/selectors/historyActions'

import { TranslationContext } from '~/contexts/TranslationContext'
import './Toolbar.scss'
import { selectCopiedAssets } from '~/selectors/select-copied-assets'
import { ReactComponent as AssetAddIcon } from '~/assets/video-player/ic_asset_add.svg'
import { isRecordingSaving, isRecordingStarted } from '~/selectors/recording'
import { PreviewPlayerToolbar } from '~/components/PreviewPlayerToolbar/'
import { selectorSplitPlayers, selectorPreviewClipCreator } from '~/selectors/preview'
import { TIMELINE_DATASET_IDS } from '~/config/constants/timeline'


function TimelineToolbar({ disabled, onMoveSlider }) {
  const dispatch = useDispatch()
  const assets = useSelector(Selectors.getAssets)
  const isSliderTimeIntoTransition = useSelector(Selectors.selectIsSliderTimeIntoTransition)
  const copiedAssets = useSelector(selectCopiedAssets)
  const sliderTime = useSelector(state => state.timeline.sliderTime)
  const isPointerAtFirstAction = useSelector(selectIsPointerAtFirstAction)
  const isPointerAtLastAction = useSelector(selectIsPointerAtLastAction)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const isSliderIntersectSelectedAssets = useSelector(Selectors
    .isSliderIntersectSelectedAssets)
  const mediaRecording = useSelector(isRecordingStarted)
  const isSaving = useSelector(isRecordingSaving)
  const isSplittedPreviewPlayers = useSelector(selectorSplitPlayers)
  const { timeline } = useSelector(selectorPreviewClipCreator)

  const split = useAction(Actions.layer.split, null)
  const splitRippleNext = useAction(Actions.layer.rippleTrim, { ripple: 'next' })
  const splitRipplePrev = useAction(Actions.layer.rippleTrim, { ripple: 'prev' })
  const deleteAssets = useAction(Actions.timeline.deleteSelectedAssets)
  const deleteTimelineFragment = useAction(Actions.timeline.deleteTimelineFragment)
  const rippleDeleteTimelineFragment = useAction(Actions.timeline.rippleDeleteTimelineFragment)
  const historyUndo = useAction(historyUndoAction)
  const historyRedo = useAction(historyRedoAction)
  const rippleDelete = useAction(rippleDeleteAction)
  const copyAssets = useAction(Actions.layer.assetsCopy)
  const copyTimelineFragment = useAction(Actions.layer.copyTimelineFragment)
  const pasteAssets = useAction(assetsPaste)
  const onAddToTimeline = useAction(addSourceFileAtSliderPosition, selectedClipId)

  const selectedAssets = assets.filter(el => el.selected && !(el instanceof PlaceholderAsset))
  const onSettingsClicked = React.useCallback(() => {
    const asset = selectedAssets[0]
    dispatch(Actions.mainView.openAssetSettingForm(asset.id))
  }, [ dispatch, selectedAssets ])

  const isSingleAssetSelected = selectedAssets.length === 1
  const cutButtonIsDisabled = isSliderTimeIntoTransition
  || !selectedAssets.length || !isSliderIntersectSelectedAssets
  const isUndoAvailable = !isPointerAtFirstAction
  const isRedoAvailable = !isPointerAtLastAction
  const isSelectedAssets = selectedAssets.length
  const isCopiedAssets = copiedAssets.length
  const disabledCtrls = mediaRecording || isSaving
  const ioPointsExists = !isNil(timeline.markIn) || !isNil(timeline.markOut)
  const deleteAction = ioPointsExists && !isSelectedAssets
    ? deleteTimelineFragment
    : deleteAssets
  const onRippleDelete = ioPointsExists && !isSelectedAssets
    ? rippleDeleteTimelineFragment
    : rippleDelete
  const copyAssetsAction = ioPointsExists && !isSelectedAssets
    ? copyTimelineFragment
    : copyAssets

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.CUT_CLIPS], split)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_TRIM_NEXT], splitRippleNext)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_TRIM_PREV], splitRipplePrev)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_DELETE], () => onRippleDelete())

  const onClickUndo = () => {
    if (isUndoAvailable) {
      historyUndo()
    }
  }
  const onClickRedo = () => {
    if (isRedoAvailable) {
      historyRedo()
    }
  }

  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <div className={cx('timeline-toolbar', { 'timeline-toolbar--disabled': disabled })} data-id={TIMELINE_DATASET_IDS.TOOLBAR}>
        <If condition={__CFG__.TIMELINE.TOOLBAR}>
          <div className="timeline-toolbar__left">
            <If condition={__CFG__.TIMELINE.UNDO_REDO}>
              <div className="timeline-toolbar__undo-redo">
                <div className="timeline-toolbar__history-item" onClick={onClickUndo} title={`${getTooltip(t('TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP'), HOTKEYS.UNDO)} `}>
                  <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_UNDO">
                    <PrevIcon className={cx({ 'timeline-toolbar__history--disabled': !isUndoAvailable })} />
                  </RawButton>
                </div>

                <div className="timeline-toolbar__history-item" onClick={onClickRedo} title={getTooltip(t('TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP'), HOTKEYS.REDO)}>
                  <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_REDO">
                    <NextIcon className={cx({ 'timeline-toolbar__history--disabled': !isRedoAvailable })} />
                  </RawButton>
                </div>
              </div>
            </If>
          </div>

          <If condition={__CFG__.TIMELINE.TIME_INPUT}>
            <div className="timeline-toolbar__duration">
              <TimeCode
                progress={sliderTime}
                containerLocation={TIME_CODE_CONTAINER_LOCATION.TIMELINE}
                isTimelineTime
                defaultTimeCodeMode={__CFG__.TIMELINE.TIME_CODE_MODE ?? TIME_CODE_MODE.FRAME}
                onMoveSlider={onMoveSlider}
              />
            </div>
          </If>

          <div className="timeline-toolbar__buttons">
            <If condition={__CFG__.TIMELINE.DELETE}>
              <div className="timeline-toolbar__item timeline-toolbar__item--delete" onClick={deleteAction} title={getTooltip(t('TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP'), HOTKEYS.DELETE)}>
                <RawButton disabled={selectedAssets.length === 0 && !ioPointsExists} dataLangId="TIMELINE_TOOLBAR_BTN_DELETE">
                  <div className="icon deleteIcon" />
                </RawButton>
              </div>
            </If>
            <If condition={__CFG__.TIMELINE.CUT}>
              <div className="timeline-toolbar__item timeline-toolbar__item--cut" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP'), HOTKEYS.CUT_CLIPS)}>
                <RawButton onClick={split} disabled={cutButtonIsDisabled} dataLangId="IMELINE_TOOLBAR_BTN_CUT">
                  <CutIcon />
                </RawButton>
              </div>
            </If>
            <If condition={__CFG__.TIMELINE.COPY_PASTE}>
              <div className="timeline-toolbar__item timeline-toolbar__item--copy" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP'), HOTKEYS.COPY)}>
                <RawButton onClick={copyAssetsAction} disabled={!isSelectedAssets && !ioPointsExists} dataLangId="TIMELINE_TOOLBAR_BTN_COPY">
                  <CopyIcon />
                </RawButton>
              </div>

              <div className="timeline-toolbar__item timeline-toolbar__item--paste" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP'), HOTKEYS.PASTE)}>
                <RawButton onClick={pasteAssets} disabled={!isCopiedAssets} dataLangId="TIMELINE_TOOLBAR_BTN_PASTE">
                  <PasteIcon />
                </RawButton>
              </div>
            </If>
            <If condition={__CFG__.TIMELINE.ADD_MARKER}>
              <div className="timeline-toolbar__item" title={t('TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP')}>
                <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_ADD_MARKER">
                  <CheckIcon />
                </RawButton>
              </div>
            </If>

            <If condition={__CFG__.TIMELINE.SETTINGS}>
              <div className="timeline-toolbar__item" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP'), HOTKEYS.CLIP_SETTINGS)}>
                <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_SETTINGS" onClick={onSettingsClicked} disabled={!isSingleAssetSelected}>
                  <SettingsIcon />
                </RawButton>
              </div>
            </If>

            <If condition={__CFG__.TIMELINE.RECORD_AUDIO}>
              <div className="timeline-toolbar__item timeline-toolbar__item--mediarecording">
                <MediaRecordingCombobox />
              </div>
            </If>
            <div className="timeline-toolbar__item">
              <RawButton className="add-icon" onClick={onAddToTimeline} disabled={!selectedClipId || disabledCtrls}>
                <AssetAddIcon />
              </RawButton>
            </div>
          </div>
        </If>
      </div>
      <If condition={isSplittedPreviewPlayers}>
        <div className="timeline-toolbar__item preview-player-toolbar__right">
          <PreviewPlayerToolbar />
        </div>
      </If>
    </Box>
  )
}

export default memo(TimelineToolbar)
