import { useCallback } from 'react'
// eslint-disable-next-line no-unused-vars
import { useDropzone, DropzoneState, DropzoneOptions } from 'react-dropzone'
import * as Actions from 'actions'
import { useAction } from './utils'
import { NW_JS_APP_PROFILES, DROP_TARGET_TYPE } from '~/enums'
import { setDndDropTarget } from '~/actions/mainView'

/**
 * @param {DropzoneOptions} [dropzoneParams]
 * @param {(file: File) => Promise<unknown>} [uploadService]
 * @param {boolean} uploadOnDrop
 * @returns {DropzoneState}
 * @see https://react-dropzone.js.org/#usage
 */
export const useLocalFileImport = (dropzoneParams = {},
  uploadService, uploadOnDrop = true) => {
  let uploadFileService = useAction(!NW_JS_APP_PROFILES.includes(__APP_PROFILE__)
    ? Actions.upload.uploadFile
    : Actions.importLocalFile.importLocalFile)
  const onSetDndDropTarget = useAction(setDndDropTarget)

  if (uploadService) uploadFileService = uploadService

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (uploadOnDrop) {
      onSetDndDropTarget(DROP_TARGET_TYPE.ROOT)
      acceptedFiles.forEach(file => uploadFileService(file))
      fileRejections.forEach(({ file }) => uploadFileService(file))
    } else if (dropzoneParams?.onDrop) {
      dropzoneParams.onDrop(acceptedFiles, fileRejections)
    }
  }, [ uploadOnDrop, uploadFileService, dropzoneParams, onSetDndDropTarget ])

  const {
    acceptedFiles,
    fileRejections,
    ...rest
  } = useDropzone({
    accept: __CFG__.SOURCE_FILES_MANAGEMENT.SUPPORTED_FILES,
    ...dropzoneParams,
    onDrop,
  })
  return { acceptedFiles, fileRejections, ...rest }
}
