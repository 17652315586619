import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from 'components/base/Radio'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import Button from '~/components/base/Button'
import Switch from '~/components/base/Switch'
import { NW_JS_APP_PROFILES, PROJECT_EXPORT_TYPE } from '~/enums'
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '~/components/base/Dialog/Dialog'
import './ProjectExportDialog.scss'
import { createExportProjectTask, getExportProjectStatus, deleteExportProjectTask } from '~/ServerAPI'
import { getPathFromPathName } from '../../../../Util'
import * as ProjectExportSettings from '~/cookieServices/ProjectExportSettings'

const EXPORT_PROJECT_STATUS = {
  NEW: 'new',
  RUNNING: 'running',
  COMPLETED: 'completed',
  DOWNLOADING: 'downloading',
  PACK: 'pack',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
}

const TASK_STATUS_INTERVAL = 500
const triggerDownload = url => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.download = url.split('/').pop()
  a.click()
}

const triggerBrowse = absPathName => {
  // eslint-disable-next-line no-unused-expressions
  nw?.Shell?.showItemInFolder(absPathName)
}

export const ProjectExportDialog = ({ modalDialog, projectId }) => {
  const settings = ProjectExportSettings.get()
  const [ exportType, setExportType ] = useState(settings.exportType)
  const [ includeMedia, setIncludeMedia ] = useState(settings.includeMedia)
  const [ error, setError ] = useState(null)
  const [ taskId, setTaskId ] = useState(null) // when taskId not null means processing
  const [ taskProgress, setTaskProgress ] = useState(-1)
  const [ outputPath, setOutputPath ] = useState('')
  const [ browsePathName, setBrowsePathName ] = useState('')
  const [ curAbsPathName, setCurAbsPathName ] = useState(null)
  const [ downloadUrl, setDownloadUrl ] = useState(null) // when downloadUrl apears means operation completed success

  const inputRef = useRef()
  const inputStyle = { display: 'none' }
  const format = `${exportType}.zip`

  const monitorTaskStatus = useCallback(async internalId => {
    try {
      const { status, url, error, progress } = await getExportProjectStatus(internalId)
      if (progress && taskProgress !== progress) {
        setTaskProgress(progress)
      }
      if (status === EXPORT_PROJECT_STATUS.COMPLETED) {
        setTaskId(null)
        setDownloadUrl(url)
      }
      if (status !== EXPORT_PROJECT_STATUS.COMPLETED
        && status !== EXPORT_PROJECT_STATUS.ABORTED) {
        setTimeout(monitorTaskStatus, TASK_STATUS_INTERVAL, internalId)
      }
      if (error) {
        setError(error.message)
        setTaskId(null)
      }
    } catch (error) {
      setError(error.message)
      setTaskId(null)
    }
  }, [ taskProgress ])


  const handleExport = useCallback(async outputAbsPath => {
    try {
      const response = await createExportProjectTask({ exportType,
        includeMedia,
        projectId,
        outputAbsPath })
      setTaskId(response.data.id)
      monitorTaskStatus(response.data.id)
    } catch (error) {
      setError(error.message)
      setTaskId(null)
    }
  }, [ exportType, includeMedia, projectId, monitorTaskStatus ])


  const handleChangeExportType = e => {
    const exportType = e.target.value
    ProjectExportSettings.set({ exportType })
    setExportType(exportType)
  }
  const { t } = useContext(TranslationContext)

  const handleChangeIncludeMedia = e => {
    const includeMedia = e.target.checked
    ProjectExportSettings.set({ includeMedia })
    setIncludeMedia(includeMedia)
  }

  useEffect(() => {
    if (modalDialog.active) {
      setError(null)
      setTaskProgress(-1)
      setTaskId(null)
      setDownloadUrl(null)
    }
  }, [ modalDialog.active ])

  const onOutputFileSelected = useCallback(e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      const pathName = file.path || file.name
      setCurAbsPathName(pathName)
    }
  }, [ ])

  const handleExportButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    } else {
      handleExport()
    }
  }

  const handleCloseButtonClick = async () => {
    if (taskId) {
      setTaskId(null)
      deleteExportProjectTask(taskId)
    }
    modalDialog.cancel()
  }

  useEffect(() => {
    if (curAbsPathName) {
      const outputPath = getPathFromPathName(curAbsPathName)
      setCurAbsPathName(null)
      setOutputPath(outputPath)
      setBrowsePathName(curAbsPathName)
      handleExport(curAbsPathName)
    }
  }, [ curAbsPathName, handleExport ])

  const handleDownloadButtonClick = () => {
    NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? triggerBrowse(browsePathName)
      : triggerDownload(downloadUrl)
  }
  const handleButtonClickCopy = () => {
    navigator.clipboard.writeText(downloadUrl)
  }


  return (
    <If condition={modalDialog.active}>
      <Dialog
        className="project-export-dialog"
        open
        modalDialog={modalDialog}
        onClose={handleCloseButtonClick}
      >
        <DialogTitle className="project-export-dialog__header">
          {downloadUrl ? t('PROJECTS_EXPORT_COMPLETE') : t('PROJECTS_EXPORT_TITLE')}
        </DialogTitle>
        <DialogContent className="project-export-dialog__content">
          <If condition={taskId}>
            <div>
              {t('PROJECTS_EXPORT_INPROGRESS')}
            </div>
            <div className="project-export-dialog__loader">
              <div className="project-export-dialog__loader-inner" style={{ width: `${taskProgress === -1 ? 0 : taskProgress}%` }} />
            </div>
          </If>
          <If condition={error}>
            <div>
              Error:
              {error}
            </div>
          </If>
          <If condition={!taskId && !error && !downloadUrl}>
            <RadioGroup value={exportType} onChange={handleChangeExportType}>
              {Object.entries(PROJECT_EXPORT_TYPE).map(([ name, value ]) => (
                <FormControlLabel key={name} className="project-export-dialog__label" value={value} control={<Radio color="primary" />} label={name} />
              ))}
            </RadioGroup>
            <FormControlLabel
              label={t('PROJECTS_EXPORT_INCLUDE_MEDIA')}
              className="project-export-dialog__label"
              control={(
                <div className="project-export-dialog__switch">
                  <Switch
                    onChange={handleChangeIncludeMedia}
                    checked={includeMedia}
                  />
                </div>
              )}
            />
            <div className="project-export-dialog__button-container">
              <If condition={NW_JS_APP_PROFILES.includes(__APP_PROFILE__)}>
                <input ref={inputRef} type="file" value="" accept={`.${format}`} nwworkingdir={outputPath} nwsaveas={projectId} style={inputStyle} onChange={onOutputFileSelected} />
              </If>
              <Button
                type="submit"
                className="project-export-dialog__button"
                primary
                onClick={handleExportButtonClick}
                autoFocus
              >
                {t('PROJECTS_TITLE_TEXT_EXPORT')}
              </Button>
            </div>
          </If>
          <If condition={downloadUrl}>
            <If condition={!NW_JS_APP_PROFILES.includes(__APP_PROFILE__)}>
              <div className="project-export-dialog__link">
                <a href={downloadUrl} target="_blank" rel="noopener noreferrer">{downloadUrl}</a>
              </div>
            </If>
            <div className="project-export-dialog__download-copy-container">
              <If condition={!NW_JS_APP_PROFILES.includes(__APP_PROFILE__)}>
                <div className="project-export-dialog__btn-copy" title={t('PROJECTS_EXPORT_BTN_COPY')}>
                  <Button onClick={handleButtonClickCopy} border data-lang-id="PROJECTS_EXPORT_BTN_COPY">{t('PROJECTS_EXPORT_BTN_COPY')}</Button>
                </div>
              </If>
              <Button
                type="submit"
                className="project-export-dialog__btn-download"
                third
                onClick={handleDownloadButtonClick}
                autoFocus
                data-lang-id="PROJECTS_EXPORT_BTN_DOWNLOAD"
              >
                {NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? t('PROJECTS_EXPORT_BTN_BROWSE') : t('PROJECTS_EXPORT_BTN_DOWNLOAD')}
              </Button>
            </div>
          </If>
        </DialogContent>
      </Dialog>
    </If>
  )
}
