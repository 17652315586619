import { PLAYBACK_STATE } from 'enums'
import { createSelector } from 'reselect'
import { PlayerType } from 'types/common'
import { TimeCodeContainerLocationType, TimeCodeModeType } from 'types/timecode'

export const selectActiveTimeCode = createSelector([
  state => state.playback.activeTimeCodeContainer,
], activeTimeCodeContainer => activeTimeCodeContainer as TimeCodeContainerLocationType)

export const selectCopiedProgress = createSelector([
  state => state.playback.copiedTimeCode.progress,
], copiedProgress => copiedProgress as number)

export const selectTimeCodeMode = createSelector([
  state => state.playback.timeCodeMode,
], timeCodeMode => timeCodeMode as Record<TimeCodeContainerLocationType, TimeCodeModeType>)

export const selectSelectedClipId = createSelector([
  state => state.playback.selectedClipId,
], selectSelectedClipId => selectSelectedClipId as string)

export const selectTimelinePlaybackState = createSelector([
  state => state.playback.timelinePlaybackState,
], timelinePlaybackState => timelinePlaybackState as typeof PLAYBACK_STATE[keyof typeof PLAYBACK_STATE])

export const selectMediaPlaybackState = createSelector([
  state => state.playback.clipPlaybackState,
], clipPlaybackState => clipPlaybackState as typeof PLAYBACK_STATE[keyof typeof PLAYBACK_STATE])

export const selectMediaServerSeekProcessing = createSelector([
  state => state.playback.isMediaServerSeekProcessing,
], isMediaServerSeekProcessing => isMediaServerSeekProcessing as boolean)

export const selectIsBuffering = createSelector([
  state => state.playback.isBuffering,
], isBuffering => isBuffering as boolean)

export const selectTimelineSegmentPlaybackState = createSelector([
  state => state.playback.timelineSegmentPlaybackState,
], timelineSegmentPlaybackState => timelineSegmentPlaybackState as typeof PLAYBACK_STATE[keyof typeof PLAYBACK_STATE])

export const selectMemoSliderTime = createSelector([
  state => state.playback.memoSliderTime,
], memoSliderTime => memoSliderTime as Record<PlayerType, number | null>)
