import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectorPreviewClipCreator } from 'selectors/preview'
import { selectTimelineDuration } from 'selectors'
import { isEmpty, isNil, isNumber } from 'lodash'
import { PlayerType } from 'types/common'
import { MARK_TYPE } from 'config/constants/preview'
import { ClipCreatorMarkType } from 'types/preview'
import { Hotkey } from 'react-hotkeys-hook/dist/types'
import { PLAYER_TYPE } from 'enums'
import { MediaAsset } from 'models/Asset'
import { selectActiveProjectId } from 'selectors/projectData'
import { resetMarkers, setPreviewMarker, setPreviewMarkers, setSourcePlayerProgress } from 'actions/preview'
import { timelineTimeToSeconds } from 'Util'
import { stopPlaybackClip, stopPlaybackTimeline } from 'actions/playback'
import { CLIP_CREATOR_LOCAL_MARKERS } from './constant'
import {
  LocalClipCreatorMarkerType,
  LocalClipCreatorUpdateDataType,
  MouseMovementDataType,
  NewTimePointData
} from './types'

type UseIOMarkersType = {
  progress: number,
  duration: number,
  activePreview: PlayerType,
  preview: PlayerType,
  mouseMovementData: React.MutableRefObject<MouseMovementDataType>,
  minClipDuration: number,
  dispatch: Dispatch<{ type: string, payload: Record<string, string | boolean | null>}>
  showInOutPoints: boolean,
  inOutPointsTimeLineAsset: MediaAsset,
  layerAssets: MediaAsset[],
  onRewind(sec: number): void
  isDragging: boolean,
  assetID: string,
  asset: MediaAsset
  isStaticAsset: boolean,
  isMedia: boolean,
}

export const useIOMarkers = ({
  progress,
  duration,
  activePreview,
  mouseMovementData,
  minClipDuration,
  dispatch,
  showInOutPoints,
  inOutPointsTimeLineAsset,
  onRewind,
  layerAssets,
  preview,
  isDragging,
  assetID,
  asset,
  isMedia,
  isStaticAsset,
}: UseIOMarkersType) => {
  const [ localMarkers, setLocalMarkers ] = useState<LocalClipCreatorMarkerType>({} as LocalClipCreatorMarkerType)
  const clipCreatorMarkers = useSelector(selectorPreviewClipCreator)
  const timelineDuration = useSelector(selectTimelineDuration)
  const activeProjectId: string = useSelector(state => selectActiveProjectId(state))

  const handleSetLocalMarkers = useCallback((
    data: LocalClipCreatorUpdateDataType | LocalClipCreatorMarkerType, fullUpdate = false
  ) => {
    setLocalMarkers(prev => {
      if (fullUpdate) {
        return data as LocalClipCreatorMarkerType
      }
      const updateData = data as LocalClipCreatorUpdateDataType
      const current = { ...(isEmpty(prev) ? CLIP_CREATOR_LOCAL_MARKERS : prev) }
      current[updateData.preview] = { ...current[updateData.preview], [updateData.markType]: updateData.point }
      return current
    })
  }, [])

  const getNewMarkerData = useCallback(({ newTimePoint, previewType, markType }: {
    newTimePoint: number | null,
    previewType: PlayerType,
    markType: ClipCreatorMarkType
  }) => {
    const newMarker = isNumber(newTimePoint) ? newTimePoint : progress
    const isMouseMove = mouseMovementData.current.markType

    let markerData = {
      preview: previewType, point: newMarker, markType,
    }

    const currentDuration = previewType === PLAYER_TYPE.MEDIA ? duration : timelineTimeToSeconds(timelineDuration)
    const allMarkersData = { ...CLIP_CREATOR_LOCAL_MARKERS }
    const { markIn, markOut } = (!isMouseMove || isDragging)
      ? clipCreatorMarkers[activePreview]
      : localMarkers[activePreview]
    const diff = markType === MARK_TYPE.IN
      ? (markOut || currentDuration) - newMarker
      : newMarker - (markIn || 0)
    markerData = { ...markerData, point: newMarker, markType }

    return {
      markerData,
      allMarkersData,
      newMarker,
      diff,
      markIn,
      markOut,
    }
  }, [
    activePreview,
    clipCreatorMarkers,
    duration,
    timelineDuration,
    localMarkers,
    progress,
    mouseMovementData,
    isDragging,
  ])

  const getLeftNRightAssetTimePosition = useCallback(
    (data: { ioPointsAssetStartTime: number, ioPointsAssetEndTime:number }) => {
      const { ioPointsAssetEndTime, ioPointsAssetStartTime } = data ?? {}
      return layerAssets.reduce((result, asset, index) => {
        const assetEndTime = asset.startTime + asset.duration
        if ((ioPointsAssetStartTime >= assetEndTime) && (assetEndTime > result.leftAssetEndTime)) {
          // eslint-disable-next-line no-param-reassign
          result.leftAssetEndTime = assetEndTime
        }
        if ((asset.startTime <= result.rightAssetStartTime) || (result.rightAssetStartTime === 0)) {
          if (ioPointsAssetEndTime <= asset.startTime) {
            // eslint-disable-next-line no-param-reassign
            result.rightAssetStartTime = asset.startTime
          }
        }
        return result
      }, { leftAssetEndTime: 0, rightAssetStartTime: 0 })
    }, [ layerAssets ]
  )

  const saveInSessionStorage = useCallback((data, isMedia) => {
    let currentData = { ...data }
    const id = isMedia ? assetID : activeProjectId
    const initialMarkers = isMedia ? CLIP_CREATOR_LOCAL_MARKERS.media : CLIP_CREATOR_LOCAL_MARKERS.timeline
    const ioPoints = sessionStorage.getItem(id)
    currentData = ioPoints
      ? { ...JSON.parse(ioPoints), ...currentData }
      : { ...initialMarkers, ...currentData }
    sessionStorage.setItem(id, JSON.stringify(currentData))
  }, [ assetID, activeProjectId ])

  const onSetPreviewMarker = useCallback((
    newPoint: number, markType: ClipCreatorMarkType, preview: PlayerType
  ) => dispatch(
    setPreviewMarker({
      preview,
      point: newPoint,
      markType: markType === MARK_TYPE.IN ? MARK_TYPE.IN : MARK_TYPE.OUT,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [])

  const onSetMarker = useCallback((
    previewType: PlayerType, markType: Exclude<ClipCreatorMarkType, 'clearInOutPoints'>
  ) => (e: globalThis.MouseEvent | KeyboardEvent,
    data: NewTimePointData | Hotkey) => {
    const { newTimePoint, offsetDraggedIOPointsSegment } = (data ?? {}) as NewTimePointData
    const isTimeline = !isMedia
    const isMouseMove = mouseMovementData.current.markType
    if (previewType === activePreview) {
      let {
        allMarkersData,
        markerData,
        diff,
        newMarker,
        markIn,
        markOut,
      } = getNewMarkerData({ newTimePoint, previewType, markType })

      const isAllowed = (diff >= minClipDuration)
      let isAllowedAllMarkers = false

      // Initial extreme points for media clip
      let maxLeftPointSec = null
      let maxRightPointSec = null

      let inPoint = markIn
      let outPoint = markOut

      outPoint = outPoint ?? 0
      inPoint = inPoint ?? 0

      // Checking the range of set values for the minimum clip length and collapsing boundaries
      const isCollapsed = !isDragging && ((diff === 0) || (diff < minClipDuration))

      /** ****** [start] Only for timeline asset in source media ****** */
      if (inOutPointsTimeLineAsset && isMedia) {
        const {
          mediaStart,
          startTime,
          duration: ioPointsDuration,
          mediaFileDuration,
        } = inOutPointsTimeLineAsset

        const ioPointsAssetEndTime = startTime + ioPointsDuration
        // Find the nearest assets to the left and right of inOutPointsTimeLineAsset
        const { leftAssetEndTime, rightAssetStartTime } = getLeftNRightAssetTimePosition({
          ioPointsAssetStartTime: startTime,
          ioPointsAssetEndTime,
        })

        // if exists left side asset
        if (leftAssetEndTime > 0) {
          const diffBetweenLeftAssetNCurrent = startTime - leftAssetEndTime
          // the distance allows the clip to be fully expanded to left
          if (diffBetweenLeftAssetNCurrent >= mediaStart) {
            maxLeftPointSec = 0
          } else {
            maxLeftPointSec = timelineTimeToSeconds(mediaStart - diffBetweenLeftAssetNCurrent)
          } // if there is no space on the left on the timeline
        } else if (mediaStart > startTime) {
          maxLeftPointSec = timelineTimeToSeconds(mediaStart - startTime)
        }

        // if exists right side asset
        if (rightAssetStartTime > 0) {
          const maxExpandRightTime = startTime + mediaFileDuration - mediaStart
          // the distance allows the clip to be fully expanded to right
          if (maxExpandRightTime <= rightAssetStartTime) {
            maxRightPointSec = timelineTimeToSeconds(mediaFileDuration)
          } else {
            maxRightPointSec = timelineTimeToSeconds(
              mediaFileDuration - (maxExpandRightTime - rightAssetStartTime)
            )
          }
        }
      }
      /** ***** [end] Only for timeline asset in source media ******* */

      /** ***** [start] Only for controlling clip boundaries by moving the mouse ***** */
      if (isMouseMove) {
        switch (markType) {
          case MARK_TYPE.IN:
            if (isCollapsed) {
              markerData.point = !isNil(markOut) ? markOut - minClipDuration : duration - minClipDuration
            } else {
              markerData.point = newMarker > (maxLeftPointSec ?? 0) ? newMarker : (maxLeftPointSec ?? 0)
            }
            break
          case MARK_TYPE.OUT:
            if (isCollapsed) {
              markerData.point = !isNil(markIn) ? markIn + minClipDuration : minClipDuration
            } else {
              markerData.point = newMarker < (maxRightPointSec ?? duration)
                ? newMarker
                : (maxRightPointSec ?? duration)
            }
            break
        }
      }
      /** ***** [end] Only for controlling clip boundaries by moving the mouse ***** */

      /** ***** [start] Only for player buttons control ***** */
      if (!isMouseMove) {
        if (inOutPointsTimeLineAsset && isMedia) {
          if (isCollapsed) {
            inPoint = markType === MARK_TYPE.IN ? outPoint - minClipDuration : inPoint
            outPoint = markType === MARK_TYPE.IN ? outPoint : inPoint + minClipDuration
            allMarkersData = {
              ...allMarkersData,
              [previewType]: {
                [MARK_TYPE.IN]: inPoint,
                [MARK_TYPE.OUT]: outPoint,
              },
            }
            isAllowedAllMarkers = true
          } else if (markType === MARK_TYPE.IN) {
            markerData.point = newMarker > (maxLeftPointSec ?? 0) ? newMarker : inPoint
          } else if (markType === MARK_TYPE.OUT) {
            const currentDuration = isTimeline ? timelineDuration : duration
            markerData.point = newMarker < (maxRightPointSec ?? currentDuration) ? newMarker : outPoint
          } // Only for media. The clip in the timeline is not selected
        } else if (isCollapsed && !isDragging) {
          inPoint = markType === MARK_TYPE.IN ? newMarker : null
          outPoint = markType === MARK_TYPE.IN ? null : newMarker

          allMarkersData = {
            ...allMarkersData,
            [previewType]: {
              [MARK_TYPE.IN]: inPoint,
              [MARK_TYPE.OUT]: outPoint,
            },
          }
          isAllowedAllMarkers = true
        }
      }
      /** ***** [end] Only for player buttons control ***** */

      if (isDragging && offsetDraggedIOPointsSegment) {
        outPoint = outPoint ?? 0
        inPoint = inPoint ?? 0
        const diff = outPoint - inPoint
        maxLeftPointSec = 0
        maxRightPointSec = duration

        const newMarkIn = inPoint + offsetDraggedIOPointsSegment
        const newMarkOut = outPoint + offsetDraggedIOPointsSegment
        const isAvaliableLeft = newMarkIn > maxLeftPointSec
        const isAvaliableRight = newMarkOut < maxRightPointSec

        inPoint = isAvaliableLeft ? newMarkIn : maxLeftPointSec
        outPoint = isAvaliableRight ? newMarkOut : maxRightPointSec

        inPoint = !isAvaliableRight ? maxRightPointSec - diff : inPoint
        outPoint = !isAvaliableLeft ? maxLeftPointSec + diff : outPoint

        allMarkersData = {
          ...allMarkersData,
          [previewType]: {
            [MARK_TYPE.IN]: inPoint,
            [MARK_TYPE.OUT]: outPoint,
          },
        }
      }

      let sourceMediaIOPoints = {}
      let newRewindPoint = null

      if (!isMouseMove && !isDragging) {
        if (isAllowed) {
          dispatch(setPreviewMarker(markerData))
          sourceMediaIOPoints = { [markerData.markType]: markerData.point }
          newRewindPoint = markerData.point
        } else if (isAllowedAllMarkers) {
          dispatch(setPreviewMarkers(allMarkersData, { preview: previewType }))
          sourceMediaIOPoints = { ...allMarkersData.media }
          newRewindPoint = allMarkersData[preview][markType as 'markIn' | 'markOut'] ?? 0
        }
      } else if (isMouseMove) {
        sourceMediaIOPoints = { [markerData.markType]: markerData.point }
        newRewindPoint = markerData.point
        if (isNumber(newRewindPoint) && mouseMovementData.current.markType) {
          onSetPreviewMarker(newRewindPoint, mouseMovementData.current.markType, preview)
        }
        handleSetLocalMarkers(markerData)
      } else if (isDragging && offsetDraggedIOPointsSegment && mouseMovementData.current.dragging) {
        dispatch(setPreviewMarkers(allMarkersData, { preview: previewType }))
        sourceMediaIOPoints = { ...allMarkersData.media }
        newRewindPoint = allMarkersData[preview].markIn ?? 0
      }

      // seek player
      if (isNumber(newRewindPoint) && (!isStaticAsset || !isMedia)) {
        onRewind(newRewindPoint)
      }

      // save in session for media source resource only
      if ((!inOutPointsTimeLineAsset && isMedia) || !isMedia) {
        saveInSessionStorage(sourceMediaIOPoints, isMedia)
      }
    }
  }, [
    handleSetLocalMarkers,
    dispatch,
    activePreview,
    minClipDuration,
    mouseMovementData,
    getNewMarkerData,
    duration,
    inOutPointsTimeLineAsset,
    onRewind,
    getLeftNRightAssetTimePosition,
    isDragging,
    preview,
    saveInSessionStorage,
    isStaticAsset,
    isMedia,
    timelineDuration,
    onSetPreviewMarker,
  ])

  const onClearInOutPoints = useCallback(() => () => {
    if ((activePreview === PLAYER_TYPE.MEDIA) && !inOutPointsTimeLineAsset) {
      dispatch(resetMarkers({ preview: activePreview }))
      if (assetID) sessionStorage.removeItem(assetID)
    } else if (activePreview === PLAYER_TYPE.TIMELINE) {
      dispatch(resetMarkers({ preview: activePreview }))
    }
  }, [ dispatch, inOutPointsTimeLineAsset, activePreview, assetID ])

  useEffect(() => {
    if (!showInOutPoints) setLocalMarkers({} as LocalClipCreatorMarkerType)
  }, [ showInOutPoints, setLocalMarkers ])

  const onClickMarker = useCallback(
    (previewType: PlayerType, markType: Exclude<ClipCreatorMarkType, 'clearInOutPoints'>) => (
      e: globalThis.MouseEvent | KeyboardEvent
    ) => {
      e.stopPropagation()
      dispatch(isMedia ? stopPlaybackClip() : stopPlaybackTimeline())
      // setTimeout - because the preview player progress is async, and player don't stop before onSetMarker.
      setTimeout(() => {
        onSetMarker(previewType, markType)(e, {})
        if (isStaticAsset && previewType !== PLAYER_TYPE.TIMELINE) {
          dispatch(setSourcePlayerProgress(progress))
        }
      })
    }, [ onSetMarker, dispatch, progress, isStaticAsset, isMedia ]
  )

  return {
    clipCreatorMarkers,
    handleSetLocalMarkers,
    localMarkers,
    setLocalMarkers,
    getNewMarkerData,
    onClearInOutPoints,
    onSetMarker,
    onClickMarker,
  }
}
