export const ASSET_SIZE_STATUS = {
  RESET: 0,
  NOT_CUT: 1,
  LEFT_CUT_RIGHT_NORMAL: 2,
  RIGHT_CUT_LEFT_NORMAL: 3,
  LEFT_CUT_RIGHT_CUT: 4,
}

export const RESTORED_ASSET_PROPERTIES = [ 'duration',
  'startTime',
  'isModified',
  'isTrim',
  'mediaStart',
  'offsetLeft',
  'offsetRight',
]

export const ASSET_ID_STARTWITH_LITERAL = 'asset'

export const ASSET_COPY_TYPE = {
  DEFAULT_SELECT: 'default_selected_assets',
  IO_POINTS_FRAGMENT: 'io_points_fragment',
}
