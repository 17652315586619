import React, { FC, useContext } from 'react'
import cx from 'classnames'
import { TimelineScrollPositionContext } from 'components/Timeline/ScrollPositionContext'

type LineType = {
  translateRightX: number,
  translateLeftX: number,
}

export const Line: FC<LineType> = ({ translateRightX, translateLeftX }) => {
  const { scrollLeft } = useContext(TimelineScrollPositionContext)

  return (
    <div
      className={cx('io-points-slider__bar')}
      style={{
        transform: `translateX(${translateLeftX - scrollLeft}px)`,
        width: !translateRightX ? `calc(100% + ${scrollLeft}px)` : `${translateRightX - translateLeftX}px`,
      }}
    />
  )
}
