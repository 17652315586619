import React, { FC } from 'react'
import { MovableModalContainer } from 'components/MovableModal'
import { useSelector } from 'react-redux'
import { selectShowNabletHld } from 'selectors/mainView'
import NabletHld from 'components/NabletHld/NabletHld'

export const NabletHldContainer: FC = () => {
  const visible = useSelector(selectShowNabletHld)
  return (
    <MovableModalContainer
      visible={visible}
      restorePosition={false}
      width={400}
      height={230}
      withBackground
    >
      <NabletHld />
    </MovableModalContainer>
  )
}
