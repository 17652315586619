import { PLAYER_TYPE } from 'enums'
import { debounce } from 'lodash'
import { MouseEvent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isRecordingStarted } from 'selectors/recording'
import { togglePlaybackPlaying } from 'actions/playback'
import { PlayerType } from 'types/common'
import { setActivePreview } from 'actions/preview'
import { selectorActivePreview } from 'selectors/preview'

export const usePreviewCommon = ({ playing, playerType }:{
  playing: number,
  playerType: typeof PLAYER_TYPE[keyof typeof PLAYER_TYPE]
}) => {
  const mediaRecordingInProgress = useSelector(isRecordingStarted)
  const activePreview = useSelector(selectorActivePreview)
  const dispatch = useDispatch()
  const togglePlaying = useCallback((e: MouseEvent) => {
    e?.preventDefault()
    dispatch(togglePlaybackPlaying({ playerType }))
  }, [ dispatch, playerType ])

  const stopRecording = useCallback(() => {
    dispatch(stopRecording())
  }, [ dispatch ])

  const handleStopRecording = playing
    ? debounce(stopRecording, 500, { leading: true, trailing: false })
    : () => {}

  const onSelect = useCallback((playerPreview: PlayerType) => (e: MouseEvent) => {
    e.stopPropagation()
    if (activePreview !== playerPreview) {
      dispatch(setActivePreview(playerPreview))
    }
  }, [ dispatch, activePreview ])

  return {
    handleStopRecording,
    togglePlaying,
    mediaRecordingInProgress,
    onSelect,
  }
}
