import * as ActionTypes from 'actions/ActionTypes'
import { pick } from 'lodash'
import { all, put, select, take, takeEvery, takeLatest, call } from 'redux-saga/effects'
import { addHistoryAction, updateHistoryAction } from '~/actions/historyActions'
import { continiousChangingSettingsDebounceMs } from '~/constant'
import { AssetCreationAction } from '~/models/HistoryActions/AssetCreationAction'
import { AssetDeletionAction } from '~/models/HistoryActions/AssetDeletionAction'
import { AssetExpandedAction } from '~/models/HistoryActions/AssetExpandedAction'
import { AssetMovedAction } from '~/models/HistoryActions/AssetMovedAction'
import { AssetParamsUpdatedAction } from '~/models/HistoryActions/AssetParamsUpdatedAction'
import { AssetSettingsUpdatedAction } from '~/models/HistoryActions/AssetSettingsUpdatedAction'
import { AssetSplittedAction } from '~/models/HistoryActions/AssetSplittedAction'
import { LayerCreationAction } from '~/models/HistoryActions/LayerCreationAction'
import { LayerDeletionAction } from '~/models/HistoryActions/LayerDeletionAction'
import { LayerMovingAction } from '~/models/HistoryActions/LayerMovingAction'
import { PlaceholderDeletionAction } from '~/models/HistoryActions/PlaceholderDeletionAction'
import { ReattachTransitionAction } from '~/models/HistoryActions/ReattachTransitionAction'
import { RippleDeleteAction } from '~/models/HistoryActions/RippleDeleteAction'
import { RippleTrimAction } from '~/models/HistoryActions/RippleTrimAction'
import { TransitionCreationAction } from '~/models/HistoryActions/TransitionCreationAction'
import { TransitionAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import { LayersCreationAction } from '~/models/HistoryActions/LayersCreationAction'
import { CopiedAssetCreationAction } from '~/models/HistoryActions/CopiedAssetCreationAction'
import { AssetsMultipleSplittedAction } from '~/models/HistoryActions/AssetsMultipleSplitedAction'
import { MultipleAssetsMovedAction } from '~/models/HistoryActions/MultipleAssetsMovedAction'
import { selectCurrentHistoryAction, selectBlockAddHistoryAction } from '~/selectors/historyActions'
import { DeleteTimelineFragmentAction } from '~/models/HistoryActions/DeleteTimelineFragmentAction'


function* withBlockCheck(saga, action) {
  const block = yield select(selectBlockAddHistoryAction)
  if (!block) {
    yield call(saga, action)
  }
}

// UNDO-REDO sagas

function* addAsset({ payload }) {
  yield put(addHistoryAction(AssetCreationAction, payload))
}

function* deleteAsset({ type, payload }) {
  if ((type === ActionTypes.DELETE_ASSETS && (payload.assets.length || payload.transitions.length))
    || (type === ActionTypes.DELETE_TRANSITIONS && payload.history)) {
    yield put(addHistoryAction(AssetDeletionAction, payload))
  }
}

function* addCopiedAsset({ payload }) {
  yield put(addHistoryAction(CopiedAssetCreationAction, payload))
}

function* deletePlaceholders({ payload: { placeholders } }) {
  if (placeholders.length) {
    yield put(addHistoryAction(PlaceholderDeletionAction, { placeholders }))
  }
}

function* moveAsset({ payload }) {
  yield put(addHistoryAction(AssetMovedAction, payload))
}

function* moveMultipleAssets({ payload }) {
  yield put(addHistoryAction(MultipleAssetsMovedAction, payload))
}

function* itemsShiftedByAsset({ payload }) {
  const { delta } = payload
  yield put(updateHistoryAction({ delta }))
}

function* splitAsset({ payload }) {
  const splittedParts = yield select(Selectors.selectCurrentSplittedParts)
  if (splittedParts) {
    yield put(addHistoryAction(AssetSplittedAction,
      { ...payload, newPartAsset: splittedParts[1] }, true))
  }
}

function* multipleSplitAssets({ payload }) {
  yield put(addHistoryAction(AssetsMultipleSplittedAction, payload))
}

function* layerInserted({ payload }) {
  const { index, layer, isHovering, isAdditional } = payload
  if (!isHovering && !isAdditional) {
    yield put(addHistoryAction(LayerCreationAction, { index, layer }))
  }
}

function* layersInserted({ payload }) {
  const { index, layers, addToHistory } = payload
  if (addToHistory) {
    yield put(addHistoryAction(LayersCreationAction, { index, layers }))
  }
}

function* layerRemoved({ payload }) {
  const { layer, index, isHovering, isTransit = false, recordInHistory = true } = payload
  if (index !== -1 && !isHovering && recordInHistory) {
    yield put(addHistoryAction(LayerDeletionAction, { layer, index, isTransit }))
  }
}

// This only reorder by buttons (up, down)
function* layerReorder({ payload }) {
  const draggingLayerId = yield select(Selectors.selectDraggingLayerId)
  if (!draggingLayerId) {
    const { id: layerId, index, prevIndex } = payload
    yield put(addHistoryAction(LayerMovingAction, {
      layerId,
      index,
      prevIndex,
    }))
  }
}

// This layer reorder by drag and drop
function* layerDragStart() {
  const { payload } = yield take([ ActionTypes.LAYER_CHANGE_POSITION ])
  const { id: layerId, prevIndex } = payload
  yield take([ ActionTypes.TIMELINE_DRAG_END_LAYER ])
  const layers = yield select(Selectors.getLayers)
  const index = layers.findIndex(({ id }) => id === layerId)
  yield put(addHistoryAction(LayerMovingAction, {
    layerId,
    index,
    prevIndex,
  }))
}

// Images and texts settings
function* updateAssetSettings(action) {
  const { payload: { asset, patch: { settings }, options: { historyAction } } } = action
  if (asset && settings && historyAction) {
    yield put(addHistoryAction(AssetSettingsUpdatedAction, { asset, settings }))
  }
}

function* updateAssetPreviewSettings({
  payload: { asset, patch: { settings }, options: { historyAction } },
}) {
  if (asset && settings?.offset && historyAction) {
    yield put(addHistoryAction(AssetSettingsUpdatedAction, { asset, settings }))
  }
}

// Asset params changing (not asset settings)
function* createAssetParamsChangingAction({
  payload: { asset, patch, options: { historyAction } },
}) {
  if (!historyAction) return
  const currentHistoryAction = yield select(selectCurrentHistoryAction)
  const patchKeys = Object.keys(patch)

  function isUpdateAction() {
    return currentHistoryAction && currentHistoryAction instanceof AssetParamsUpdatedAction
      && Object.keys(currentHistoryAction?.patch).some(key => patchKeys.includes(key))
      // NOTE: this debounce for update continious history action (e.g. opacity slider)
      && Date.now() - currentHistoryAction?.modifTime < continiousChangingSettingsDebounceMs
  }

  // there is another saga for asset settings
  if (asset && patch && !patch.settings) {
    if (isUpdateAction()) {
      yield put(updateHistoryAction({ patch }))
    } else {
      yield put(addHistoryAction(AssetParamsUpdatedAction, { asset, patch }))
    }
  }
}

function* executeAddingTransition(payload) {
  yield put(addHistoryAction(TransitionCreationAction, payload))
}


function* addTransition({ payload }) {
  yield take(ActionTypes.TRANSITION_SET_START_TIME)
  yield executeAddingTransition(payload)
}

function* transitionReAttachedToAsset({ payload }) {
  yield put(addHistoryAction(ReattachTransitionAction, payload))
}

function* updateAssetExpanding({ payload: { initProps, asset } }) {
  const updatedAsset = yield select(state => Selectors.selectAssetById(state, asset.id))
  const cancelUpdate = Object.keys(initProps).every(key => initProps[key] === updatedAsset[key])

  if (cancelUpdate) return

  yield put(addHistoryAction(AssetExpandedAction,
    { asset, ...pick(updatedAsset, Object.keys(initProps)) }))
}

function* rippleDeleteAssets({ payload }) {
  if (payload.assets.length || payload.placeholders.length) {
    const stateAssets = yield select(Selectors.getAssets)
    const patchs = payload.patchedAssets.reduce((patchs, asset) => {
      const stateAsset = stateAssets.find(a => a.id === asset.id)
      if (stateAsset) {
        Object.assign(patchs, { [asset.id]: asset.getDifference(stateAsset) })
      }
      return patchs
    }, {})
    yield put(addHistoryAction(RippleDeleteAction, { ...payload, patchs }))
  }
}

function* rippleTrim({ payload }) {
  const { sliderTime, rippleTime, ripple, assets } = payload
  const stateAssets = yield select(Selectors.getAssets)
  const startTime = ripple === 'next' ? sliderTime : rippleTime
  const endTime = ripple === 'next' ? rippleTime : sliderTime
  const patchs = assets.reduce((result, asset) => {
    const currentAsset = stateAssets.find(a => a.id === asset.id)
    if (currentAsset) {
      Object.assign(result, {
        [asset.id]: asset.getDifference(currentAsset),
      })
    }
    return result
  }, {})
  const deletedAssets = assets.filter(a => !stateAssets.some(asset => asset.id === a.id))
  const deletedTransitions = TransitionAsset.filter(deletedAssets)
  const assetIds = assets.map(a => a.id)
  const generatedIds = stateAssets.filter(a => !assetIds.includes(a.id)).reduce((ids, asset) => {
    Object.assign(ids, { [asset.originAssetId]: asset.id })
    return ids
  }, {})

  yield put(addHistoryAction(RippleTrimAction, {
    startTime,
    endTime,
    ripple,
    patchs,
    deletedAssets: deletedAssets.filter(a => !(a instanceof TransitionAsset)),
    transitions: deletedTransitions,
    generatedIds,
    assetIds,
  }))
}

function* deleteTimelineFragment({ payload }) {
  if (payload.recordInHistory) {
    yield put(addHistoryAction(DeleteTimelineFragmentAction, { ...payload }))
  }
}

function* watchAll() {
  yield all(__CFG__.EDITOR.UNDO_REDO ? [
    takeEvery(ActionTypes.ASSET_ADDED_TO_TIMELINE, withBlockCheck, addAsset),
    takeEvery(ActionTypes.COPIED_ASSET_ADDED_TO_TIMELINE, withBlockCheck, addCopiedAsset),
    takeEvery(ActionTypes.DELETE_ASSETS, withBlockCheck, deleteAsset),
    takeEvery(ActionTypes.DELETE_TRANSITIONS, withBlockCheck, deleteAsset),
    takeEvery(ActionTypes.ASSET_MOVED_ON_TIMELINE, withBlockCheck, moveAsset),
    takeEvery(ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE, withBlockCheck, moveMultipleAssets),
    takeEvery(ActionTypes.SPLIT, withBlockCheck, splitAsset),
    takeEvery(ActionTypes.MULTIPLE_SPLIT, withBlockCheck, multipleSplitAssets),
    takeEvery(ActionTypes.SHIFT_ITEMS_BY_ASSET, withBlockCheck, itemsShiftedByAsset),
    takeEvery(ActionTypes.DELETE_PLACEHOLDERS, withBlockCheck, deletePlaceholders),
    takeEvery(ActionTypes.INSERT_LAYER, withBlockCheck, layerInserted),
    takeEvery(ActionTypes.INSERT_LAYERS, withBlockCheck, layersInserted),
    takeEvery(ActionTypes.LAYER_REMOVED, withBlockCheck, layerRemoved),
    takeLatest(ActionTypes.ASSET_SETTINGS_FORM_UPDATED, withBlockCheck, updateAssetSettings),
    takeEvery(ActionTypes.ASSET_PREVIEW_UPDATED, withBlockCheck, updateAssetPreviewSettings),
    takeEvery(ActionTypes.ASSET_SETTINGS_FORM_UPDATED,
      withBlockCheck, createAssetParamsChangingAction),
    takeEvery(ActionTypes.LAYER_CHANGE_POSITION, withBlockCheck, layerReorder),
    takeEvery(ActionTypes.TIMELINE_DRAG_LAYER, withBlockCheck, layerDragStart),
    takeEvery(ActionTypes.TRANSITION_REATTATCH, withBlockCheck, transitionReAttachedToAsset),
    takeEvery(ActionTypes.TRANSITION_ADDED_TO_TIMELINE, withBlockCheck, addTransition),
    takeEvery(ActionTypes.STOP_ASSET_PREVIEW_UPDATING, withBlockCheck, updateAssetExpanding),
    takeEvery(ActionTypes.RIPPLE_DELETE_ASSETS, withBlockCheck, rippleDeleteAssets),
    takeEvery(ActionTypes.RIPPLE_TRIM, withBlockCheck, rippleTrim),
    takeEvery([
      ActionTypes.DELETE_IO_POINTS_TIMELINE_FRAGMENT,
      ActionTypes.RIPPLE_DELETE_IO_POINTS_TIMELINE_FRAGMENT,
    ], withBlockCheck, deleteTimelineFragment),
  ] : [])
}

export default watchAll
