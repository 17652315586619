import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'

const initialState = {
  onnxNames: [ ],
  fileId: null,
  taskId: null,
  status: null,
  progress: 0,
  error: null,
  cutLists: null,
}
const setFileId = (state, action) => {
  const { fileId } = action.payload
  state.fileId = fileId
}

const setOnnxNames = (state, action) => {
  const { onnxNames } = action.payload
  state.onnxNames = onnxNames
}


const clearTask = state => {
  state.taskId = null
  state.status = null
  state.fileId = null
  state.progress = 0
  state.error = null
  state.cutLists = null
}

const updateTaskStatus = (state, action) => {
  const { taskId, status, progress, error, cutLists } = action.payload
  if (taskId) {
    state.taskId = taskId
  }
  if (status) {
    state.status = status
  }
  if (progress) {
    state.progress = progress
  }
  if (error) {
    state.error = error
  }
  if (cutLists) {
    state.cutLists = cutLists
  }
}

const nabletHld = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.CLEAR_HLD_TASK:
      clearTask(state, action)
      break
    case ActionTypes.UPDATE_HLD_TASK_STATUS:
      updateTaskStatus(state, action)
      break
    case ActionTypes.SET_HLD_FILE_ID:
      setFileId(state, action)
      break
    case ActionTypes.SET_ONNX_NAMES:
      setOnnxNames(state, action)
      break
  }
}

export default produce(nabletHld, initialState)
