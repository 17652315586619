import { generateClientId } from '~/models/Asset/Asset'
import { selectCopiedAssets } from '~/selectors/select-copied-assets'
import * as Selectors from '~/selectors'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'
import { PlaceholderAsset, TransitionAsset } from '~/models/Asset'
import { addCopiedAssetsToTimeLine } from '~/actions/projectData/addAssetToTimeline'
import { createNewLayers } from '~/actions/createNewLayers'
import { TRANSITIONS } from '~/enums'

export const assetsPaste = () => (dispatch, getState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const lastCopiedAssets = [ ...selectCopiedAssets(state) ]
  const allAssets = Selectors.getAssets(state).filter(a => !(a instanceof PlaceholderAsset))
  const layers = Selectors.getLayers(state)
  const layerIds = layers.map(l => l.id)

  const sortCopiedAssets = lastCopiedAssets.sort((a, b) => a.startTime - b.startTime)
  const startTimeCopyAssetsGroup = sortCopiedAssets[0].startTime
  const shiftTime = sliderTime - startTimeCopyAssetsGroup

  const shiftCopiedAssets = sortCopiedAssets.map(a => {
    const clone = cloneInstanceClass(a)
    clone.startTime += shiftTime
    return clone
  })

  const copiedAssetsByLayerLevels = shiftCopiedAssets.reduce((result, asset) => {
    const levels = [ ...result ]
    const index = layerIds.indexOf(asset.layerId)
    if (!levels[index]) {
      levels[index] = []
    }
    levels[index].push(asset)
    if (levels[index].length > 1) {
      levels[index].sort((a, b) => a.startTime - b.startTime)
    }
    return levels
  }, []).filter(arr => arr)

  const checkIntersection = (existLayerIndex, copiedLayerIndex) => {
    const copiedAssetsOnLayer = copiedAssetsByLayerLevels[copiedLayerIndex]
      .filter(a => !(a instanceof TransitionAsset))
    const countCopiedAssetsOnLayer = copiedAssetsOnLayer.length
    // eslint-disable-next-line
    const startTime = copiedAssetsOnLayer[0].startTime
    // eslint-disable-next-line
    const endTime = copiedAssetsOnLayer[countCopiedAssetsOnLayer - 1].endTime
    const existsLayerAssets = allAssets
      .filter(a => a.layerId === layerIds[existLayerIndex] && a.endTime >= sliderTime)
    const res = existsLayerAssets.find(asset => (
      (asset.startTime >= startTime && asset.startTime < endTime)
    || (asset.endTime > startTime && asset.endTime <= endTime)
    || (startTime > asset.startTime && endTime < asset.endTime)))

    return !!res
  }

  const countCopiedLayers = copiedAssetsByLayerLevels.length
  let countNewLayers = countCopiedLayers
  const copiedAssetsLastLayerIndex = countNewLayers - 1

  loop1:
  for (let i = 0; i < layerIds.length; i++) {
    if (i > copiedAssetsLastLayerIndex) {
      break
    }
    let copiedLayerIndex = countCopiedLayers - 1
    for (let j = i; j >= 0; j--) {
      if (checkIntersection(j, copiedLayerIndex)) {
        break loop1
      }
      copiedLayerIndex -= 1
    }
    countNewLayers -= 1
  }

  let newLayersIds = null
  if (countNewLayers > 0) {
    const newLayers = createNewLayers(dispatch, countNewLayers)
    newLayersIds = newLayers.map(l => l.id)
  }

  // layers after paste new layers
  const currentLayers = Selectors.getLayers(getState())
  const newLayersArrIds = currentLayers.map(l => l.id)

  // is tmp object for change transition asset ids
  const assetIdsMap = {}

  const prevSelectedAssetIds = []
  const prevLayersMap = {}
  const newAssets = []
  let newTransitions = []

  copiedAssetsByLayerLevels.forEach((assetArr, index) => {
    assetArr.forEach(a => {
      const clone = cloneInstanceClass(a)
      const newAssetID = generateClientId()
      if (!(clone instanceof TransitionAsset)) {
        prevSelectedAssetIds.push(clone.id)
      }
      assetIdsMap[clone.id] = newAssetID
      clone.id = newAssetID
      prevLayersMap[newAssetID] = clone.layerId
      clone.layerId = newLayersArrIds[index]
      a instanceof TransitionAsset ? newTransitions.push(clone) : newAssets.push(clone)
    })
  })

  newTransitions = newTransitions.map(trans => {
    const transition = cloneInstanceClass(trans)
    switch (true) {
      case transition.type === TRANSITIONS.DISSOLVE:
        if (assetIdsMap[transition.rightVideoAssetId]) {
          transition.rightVideoAssetId = assetIdsMap[transition.rightVideoAssetId]
        }
        if (assetIdsMap[transition.leftVideoAssetId]) {
          transition.leftVideoAssetId = assetIdsMap[transition.leftVideoAssetId]
        }
        break
      case transition.type === TRANSITIONS.FADEIN:
        if (assetIdsMap[transition.rightVideoAssetId]) {
          transition.rightVideoAssetId = assetIdsMap[transition.rightVideoAssetId]
        }
        break
      case transition.type === TRANSITIONS.FADEOUT:
        if (assetIdsMap[transition.leftVideoAssetId]) {
          transition.leftVideoAssetId = assetIdsMap[transition.leftVideoAssetId]
        }
        break
      default:
        break
    }
    return transition
  })

  dispatch(addCopiedAssetsToTimeLine(newAssets, newTransitions,
    { sliderTime, newLayersIds, prevSelectedAssetIds, prevLayersMap, insertionLayerIndex: 0 }))
}
