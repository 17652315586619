import { TRANSITIONS } from 'enums'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'
import { TransitionAsset } from '~/models/Asset'

export const getCopiedAssetsTransitions = (copiedAssets, allAssets) => {
  const resultTransitions = []
  const copiedAssetsIds = copiedAssets.map(asset => asset.id)
  const transitions = allAssets.filter(asset => asset instanceof TransitionAsset)
  transitions.forEach(transition => {
    switch (transition.type) {
      case TRANSITIONS.FADEIN:
        if (copiedAssetsIds.includes(transition.rightVideoAssetId)) {
          const newTransition = cloneInstanceClass(transition)
          newTransition.selected = false
          resultTransitions.push(newTransition)
        }
        break
      case TRANSITIONS.FADEOUT:
        if (copiedAssetsIds.includes(transition.leftVideoAssetId)) {
          const newTransition = cloneInstanceClass(transition)
          newTransition.selected = false
          resultTransitions.push(newTransition)
        }
        break
      case TRANSITIONS.DISSOLVE:
        if (copiedAssetsIds.includes(transition.leftVideoAssetId)
        && copiedAssetsIds.includes(transition.rightVideoAssetId)) {
          const newTransition = cloneInstanceClass(transition)
          newTransition.selected = false
          resultTransitions.push(newTransition)
        }
        break
      default:
        break
    }
  })
  return resultTransitions
}
