import Cookies from './service'
import { newProjectName } from '~/constant'
import { defaultCookiesParamsMaxTime } from './defaultCookiesParams'

const cookies = Cookies.getInstance()
const LATEST_PROJECT_NAME = 'latestProjectName'

export function set(latestProjectName) {
  const currentValue = get()
  if (currentValue !== latestProjectName) {
    cookies.set(LATEST_PROJECT_NAME, latestProjectName, defaultCookiesParamsMaxTime)
  }
}

export function get() {
  return cookies.get(LATEST_PROJECT_NAME) || newProjectName
}
