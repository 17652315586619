import * as ActionTypes from '~/actions/ActionTypes'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import * as LatestProjectName from '~/cookieServices/LatestProjectName'

export const setActiveProject = payload => ({
  type: ActionTypes.PROJECTS_ACTIVATE,
  payload,
})

/**
 * @param {{id: string, name: string}} payload
 */
export const activateProject = payload => async dispatch => {
  await dispatch(setActiveProject(payload))
  LatestProjectId.set(payload?.id || '')
  LatestProjectName.set(payload?.name || '')
}
