
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppRoutes } from '~/AppRoutes'
import { ReactComponent as EditorIcon } from '~/assets/projects/editor_tab.svg'
import { ReactComponent as ProjectsIcon } from '~/assets/projects/projects_tab.svg'
import { newProjectId } from '~/constant'
import { selectActiveProjectId, selectShowBlankProjectInList } from '~/selectors/projectData'
import { getProjects as getProjectsAction } from '~/actions'

export const useNavigationTabs = () => {
  const { t } = useContext(TranslationContext)
  const location = useLocation()
  const activeProjectId = useSelector(state => selectActiveProjectId(state))
  const showBlankProjectInList = useSelector(selectShowBlankProjectInList)
  const history = useHistory()
  const getProjects = useAction(getProjectsAction.getProjects)

  const navigationTabs = [
    {
      text: t('NAVIGATION_TAB_EDITOR'),
      title: t('NAVIGATION_TAB_EDITOR_TOOLTIP'),
      dataLangId: 'NAVIGATION_TAB_EDITOR',
      icon: <EditorIcon />,
      url: `${AppRoutes.Projects}/${activeProjectId}`,
      isActive: location.pathname === `${AppRoutes.Projects}/${activeProjectId}`,
      disappear: location.pathname === AppRoutes.Projects
        && (!activeProjectId || (activeProjectId === newProjectId && !showBlankProjectInList)),
      onClick: () => history.push(`${AppRoutes.Projects}/${activeProjectId}`),
    },
    {
      text: t('NAVIGATION_TAB_PROJECTS'),
      title: t('NAVIGATION_TAB_PROJECTS_TOOLTIP'),
      dataLangId: 'NAVIGATION_TAB_PROJECTS',
      icon: <ProjectsIcon />,
      url: AppRoutes.Projects,
      isActive: location.pathname === AppRoutes.Projects,
      onClick: async () => {
        await getProjects()
        history.push(`${AppRoutes.Projects}`)
      },
    },
  ]

  return navigationTabs
}
