import React, { memo } from 'react'
import SplitPane from 'react-split-pane'
import { connect, useSelector } from 'react-redux'
import { PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { isEmpty, noop } from 'lodash'

import { PREVIEW_MIN_WIDTH, SPLIT_PREVIEW_DEFAULT_WIDTH, TIMELINE_MIN_WIDTH } from 'constant'
import { PREVIEW_MODE } from 'config/constants/preview'
import { SPLIT_RESIZER } from 'types/common'
import { selectTimelineSegmentPlaybackState } from 'selectors/playback'
import { MediaPreviewContainer } from './MediaPreview/MediaPreviewContainer'
import { TimelinePreviewContainer } from './TimelinePreview/TimelinePreviewContainer'
import { PlaybackKeyHandler } from './PlaybackKeyHandler'

const OneScreenPreviewContainer = ({ activeMode, activePreview, isSplitted }) => {
  const timelineSegmentPlaybackState = useSelector(selectTimelineSegmentPlaybackState)
  if (activeMode === PREVIEW_MODE.AUTO) {
    switch (activePreview) {
      case PLAYER_TYPE.MEDIA:
        if ((timelineSegmentPlaybackState === PLAYBACK_STATE.PLAY) && !isSplitted) {
          return <TimelinePreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
        }
        return <MediaPreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
      case PLAYER_TYPE.TIMELINE:
        return <TimelinePreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
      default:
        return null
    }
  } else {
    switch (activeMode) {
      case PREVIEW_MODE.SOURCE:
        if ((timelineSegmentPlaybackState === PLAYBACK_STATE.PLAY) && !isSplitted) {
          return <TimelinePreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
        }
        return <MediaPreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
      case PREVIEW_MODE.TIMELINE:
        return <TimelinePreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
      default:
        return null
    }
  }
}


class PreviewContainer extends React.PureComponent {

  state = {
    container: null,
    size: SPLIT_PREVIEW_DEFAULT_WIDTH,
  }

  render() {
    const {
      isSplitted,
      activeMode,
      activePreview,
      onSetResizing,
      previewRef,
      resizer,
      isResizing,
    } = this.props
    const { container, size } = this.state
    const { splitPane } = container ?? {}
    const isMainResize = resizer === SPLIT_RESIZER.MAIN && isResizing

    let props = {
      pane1Style: { width: size, minWidth: PREVIEW_MIN_WIDTH },
      pane2Style: { minWidth: PREVIEW_MIN_WIDTH },
      maxSize: splitPane?.offsetWidth > TIMELINE_MIN_WIDTH
        ? splitPane?.offsetWidth - PREVIEW_MIN_WIDTH
        : PREVIEW_MIN_WIDTH,
      onChange: newSize => this.setState(prev => ({ ...prev, size: newSize })),
    }
    if (isMainResize) {
      props = {
        ...props,
        pane1Style: {
          maxWidth: SPLIT_PREVIEW_DEFAULT_WIDTH,
          minWidth: SPLIT_PREVIEW_DEFAULT_WIDTH,
        },
        pane2Style: { minWidth: SPLIT_PREVIEW_DEFAULT_WIDTH },
        maxSize: SPLIT_PREVIEW_DEFAULT_WIDTH,
        onChange: noop,
      }
    }

    return (
      <div className="preview-container">
        <PlaybackKeyHandler />
        {isSplitted
          ? (
            <SplitPane
              {...props}
              split="vertical"
              minSize={PREVIEW_MIN_WIDTH}
              defaultSize={SPLIT_PREVIEW_DEFAULT_WIDTH}
              ref={ref => {
                if (isEmpty(splitPane) && ref) {
                  previewRef.current = ref
                  this.setState(prev => ({
                    ...prev,
                    container: ref,
                  }))
                }
              }}
              onDragStarted={() => onSetResizing(true, SPLIT_RESIZER.PREVIEW)}
              onDragFinished={() => onSetResizing(false)}
              onChange={newSize => {
                this.setState(prev => ({
                  ...prev,
                  size: newSize,
                }))
              }}
            >
              <MediaPreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
              <TimelinePreviewContainer isSplitted={isSplitted} activeMode={activeMode} />
            </SplitPane>
          )
          : (
            <OneScreenPreviewContainer
              isSplitted={isSplitted}
              activeMode={activeMode}
              activePreview={activePreview}
            />
          )}
      </div>
    )
  }

}

const mapStateToProps = state => ({
  isSplitted: state.preview.splitPlayers,
  activeMode: state.preview.mode,
  activePreview: state.preview.activePreview,
  resizer: state.userInterface.resizer,
  isResizing: state.userInterface.resizing,
})

export default memo(connect(mapStateToProps)(PreviewContainer))
