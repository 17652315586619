import React, { forwardRef } from 'react'
import cx from 'classnames'
import { time2Pixel } from 'Util'
import { LAYER_HEIGHT_WITH_BORDER } from 'constant'
import { isNil } from 'lodash'

type PointType = {
  point: number | null
  scale: number,
  scrollLeft: number,
  isLeft?: boolean
  countLayers: number,
  isSelectedAsset: boolean,
}

export const Point = forwardRef<HTMLDivElement, PointType>(({
  point,
  scale,
  isLeft,
  countLayers,
  isSelectedAsset,
  scrollLeft,
}, ref) => (
  <>
    <div
      className={cx(`io-points-slider__${isLeft ? 'left' : 'right'}-shadow`,
        { hidden: !point || isSelectedAsset })}
      style={{
        width: isLeft
          ? `${time2Pixel(point, scale)}px`
          : `calc(100vw - ${time2Pixel(point, scale) - scrollLeft}px)`,
        height: countLayers * LAYER_HEIGHT_WITH_BORDER - 5,
        transform: `translateX(${isLeft ? -scrollLeft : 0}px)`,
      }}
    />
    <div
      ref={ref}
      className={cx(`io-points-slider__${isLeft ? 'left' : 'right'}-point`, { hidden: isNil(point) })}
      style={{
        transform: `translateX(${time2Pixel(point, scale) - scrollLeft}px)`,
      }}
    >
      <div />
    </div>
  </>
))
