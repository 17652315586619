import { AxiosResponse } from 'axios'
import { httpClient } from 'ServerAPI'

export type FileType = 'media' | 'project'

type ResponseBody = {
  id: string
}

type UploadSessionParams<T> = T extends 'media' ? {
  folder?: string,
} : {
  fps?: number,
  // eslint-disable-next-line camelcase
  createFolder?: boolean
}

type Body = {
  name?: string,
  size?: number,
  absPathName?: string
}

export function createUploadSession<T extends FileType>(body: Body, type: T, params: UploadSessionParams<T> = {}) {
  return httpClient.post<Body & UploadSessionParams<T>, AxiosResponse<ResponseBody>>(
    `/api/upload/${type}`, { ...body, ...params }
  )
}

type UploadFileRequestParams = RequestParams & {
  type: FileType
}

export function uploadFileRequest({ id,
  chunk, start, end,
  totalSize, type, controller = new AbortController() }: UploadFileRequestParams) {
  return httpClient.put(`/api/upload/${type}`, chunk, {
    signal: controller.signal,
    headers: {
      'Content-type': 'application/octet-stream',
      'Content-Range': `bytes ${start}-${end}/${totalSize}`,
    },
    params: {
      id,
    },
  })
}

const CHUNK_SIZE = 1024 * 256

export type RequestParams = {
  id: string,
  chunk: ArrayBuffer,
  start: number,
  end: number,
  totalSize: number,
  controller?: AbortController
}

type UploadFileParams = {
  id: string,
  file: File & { path: string },
  request: (x: RequestParams) => Promise<AxiosResponse<unknown>>
  onChunkUpload?: (start: number) => void,
  onFileUpload?: () => void,
  onError?: (e?: Error) => void,
  controller?: AbortController
}

export const uploadFile = ({ id,
  file,
  request,
  onChunkUpload = (start: number) => {},
  onFileUpload = () => {},
  onError = () => {},
  controller = new AbortController() }: UploadFileParams) => {
  let breakLoop = false

  function cancel() {
    breakLoop = true
    controller.signal.removeEventListener('abort', cancel)
  }

  controller.signal.addEventListener('abort', cancel)

  const reader = new FileReader()
  // TODO: handle error and abort
  // eslint-disable-next-line no-console
  reader.onabort = e => console.log(`file ${file.name} reading was aborted`, e)
  // eslint-disable-next-line no-console
  reader.onerror = e => console.log(`file ${file.name} reading has failed`, e)

  reader.onload = async () => {
    const binaryStr = reader.result as ArrayBuffer
    let start = 0
    let status
    if (!binaryStr) return

    while (start < binaryStr.byteLength) {
      if (breakLoop) { status = null; break }
      const end = Math.min(file.size, start + CHUNK_SIZE)
      const chunk = binaryStr.slice(start, end)
      try {
        // eslint-disable-next-line no-await-in-loop
        const { status: statusCode } = await request({ id,
          chunk,
          start,
          end: end - 1,
          totalSize: file.size,
          controller })
        status = statusCode
        // await uploadFileRequest({ id, chunk, start, end: end - 1, totalSize: file.size, controller })
      } catch {
        onError()
        return
      }

      // TODO: parse Content-Range and calculate progress
      // response.config.headers['Content-Range']
      // const response = await uploadMedia(id, chunk, start, end, file.size)
      start = end

      onChunkUpload(start)
    }
    if (status === 200) { onFileUpload() }
  }

  reader.readAsArrayBuffer(file)
  // return () => { breakLoop = true; controller.abort() }
}

export const cancelImport = async (url: string) => httpClient.delete(url)

export type ProjectUploadStage = 'upload' | 'unpack' | 'import_media' | 'ready' | 'error'

type ProgressResponseBody = {
    status: ProjectUploadStage,
    progress: number,
    error: string,
}

export const getProjectProgress = async (id: string, controller = new AbortController()) => {
  const { data } = await httpClient.get<ProgressResponseBody>(`/api/upload/project?id=${id}`, {
    signal: controller.signal,
  })

  if (data.error) {
    throw Error(data.error)
  }

  return { progress: data.progress, stage: data.status }
}
