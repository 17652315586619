import * as ActionTypes from 'actions/ActionTypes'
import { newProjectId, newProjectName } from '~/constant'
import { selectActiveProjectId, selectActiveProjectLoading, selectActiveProjectName, selectIsActiveProjectDeleted, selectProjectForSaving, selectProjectThumbnail, selectSaveEmpty } from '~/selectors/projectData'
import * as API from '~/ServerAPI'
import { createProject } from './createProject'
import { hideBlankProjectFromList } from './hideBlankProjectFromList'
import { setLatestSavedTime } from '~/actions/projectData/setLatestSavedTime'
import { ERROR_TYPE } from '~/errors/ErrorType'
import { setActiveProjectError } from './setActiveProjectError'

export const saveProjectOnExit = () => (_, getState) => {
  const state = getState()
  const activeProjectId = selectActiveProjectId(state)
  const activeProjectName = selectActiveProjectName(state)
  const thumbnail = selectProjectThumbnail(state)
  const project = selectProjectForSaving(state)
  const totalAssetsCount = project.layers.reduce((count, layer) => count + layer.assets.length, 0)
  const projectStringified = JSON.stringify(project)

  if (activeProjectId === newProjectId && totalAssetsCount) {
    const modifTime = Date.now()
    const projectParams = { name: activeProjectName,
      data: projectStringified,
      thumbnail,
      createTime: modifTime,
      modifTime }
    API.createProject(projectParams)
  } else {
    API.saveProject(activeProjectId,
      { data: projectStringified, thumbnail, modifTime: project.modifTime })
  }
}

export const saveProject = () => async (dispatch, getState) => {
  const state = getState()
  const activeProjectLoading = selectActiveProjectLoading(state)
  const activeProjectId = selectActiveProjectId(state)
  if (!activeProjectLoading && activeProjectId && __APP_PROFILE__ !== 'package') {
    const activeProjectName = selectActiveProjectName(state)
    const isActiveProjectDeleted = selectIsActiveProjectDeleted(state)

    const thumbnail = selectProjectThumbnail(state)

    const project = selectProjectForSaving(state)

    const totalAssetsCount = project.layers.reduce((count, layer) => count + layer.assets.length, 0)

    if (!isActiveProjectDeleted) {
      const projectStringified = JSON.stringify(project)

      if (activeProjectId === newProjectId) {
        const saveEmpty = selectSaveEmpty(state)
        if (saveEmpty || activeProjectName !== newProjectName || totalAssetsCount) {
          await dispatch({ type: ActionTypes.PROJECTS_START_SAVING })
          const createdProjectId = await dispatch(
            createProject(projectStringified, activeProjectName, thumbnail, project.createTime)
          )
          await dispatch(setLatestSavedTime())
          await dispatch(hideBlankProjectFromList())
          await dispatch({ type: ActionTypes.PROJECTS_END_SAVING })
          return createdProjectId
        }
      } else {
        await dispatch({ type: ActionTypes.PROJECTS_START_SAVING })
        try {
          const res = await API.saveProject(activeProjectId,
            { data: projectStringified, thumbnail, modifTime: project.modifTime })
          if (res.data?.error) {
            const { type, message } = res.data.error
            await dispatch(setActiveProjectError(type, message))
          }
        } catch (e) {
          if (e.response?.status === 413) {
            await dispatch(setActiveProjectError(ERROR_TYPE.PROJECT_SIZE_LIMIT_EXCEEDED, 'Unable to save project. The the project size limit exceeded.'))
          }
        } finally {
          await dispatch({
            type: ActionTypes.PROJECTS_REFRESH_THUMBNAIL,
            payload: { id: activeProjectId, thumbnail },
          })
          await dispatch(setLatestSavedTime())
          await dispatch({ type: ActionTypes.PROJECTS_END_SAVING })
        }
      }
    }
  }
  return activeProjectId
}

export const saveTimelineMarkersAsProjectIOPoints = timelineMarkers => ({
  type: ActionTypes.PROJECT_SAVE_IO_POINTS,
  payload: {
    ioPoints: timelineMarkers,
  },
})
