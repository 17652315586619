import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset, getPlayingAssets } from 'selectors'
import { selectTimelinePlaybackState } from 'selectors/playback'
import { getFPSByRefVideo } from 'Util'
import { PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { setPreviewLoading } from 'actions/preview'
import { selectPreviewMode } from 'selectors/preview'
import { useAction } from 'hooks/utils'
import { PREVIEW_MODE } from 'config/constants/preview'
import { usePreviewCommon } from './usePreviewCommon'

export const useTimelinePreviewContainer = () => {
  const playing = useSelector(selectTimelinePlaybackState)
  const refVideo = useSelector(getReferenceVideoAsset)
  const playingAssets = useSelector(getPlayingAssets)
  const activePreviewMode = useSelector(selectPreviewMode)
  const stopPreviewLoading = useAction(setPreviewLoading, false)

  const [ videoAsset ] = playingAssets

  const {
    handleStopRecording,
    togglePlaying,
    mediaRecordingInProgress,
    onSelect,
  } = usePreviewCommon({ playing, playerType: PLAYER_TYPE.TIMELINE })

  useEffect(() => {
    if (activePreviewMode === PREVIEW_MODE.TIMELINE) {
      stopPreviewLoading()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activePreviewMode ])

  const timelinePreviewProps = {
    isMedia: false,
    srcVideo: videoAsset?.url,
    playing: (playing === PLAYBACK_STATE.PLAY),
    onPlayPause: mediaRecordingInProgress ? handleStopRecording : togglePlaying,
    asset: videoAsset,
    fps: getFPSByRefVideo(refVideo),
    showVideoPlayer: Boolean(videoAsset) || Boolean(playingAssets?.length > 0),
    showThumbnail: false,
    previewPlayerType: PLAYER_TYPE.TIMELINE,
  }

  return {
    timelinePreviewProps,
    onSelect,
  }
}
