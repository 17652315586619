import { TRANSITIONS } from 'enums'
import { cloneInstanceClass } from 'helpers/cloneInstanceClass'
import { inRange, isNil, isNumber, isString } from 'lodash'
import { TimelineCommonAsset, TransitionAsset } from 'models/Asset'
import { generateClientId } from 'models/Asset/Asset'
import { getFPSByRefVideo, secondsToTimelineTime } from 'Util'

type OptionsType = {
  generateNewId: boolean,
  markIn: number | null,
  markOut: number | null,
  startFromZero?: boolean, // start playing resources, from time zero if ioPoints exists
}

type RefVideoType = { fpsNum: number, fpsDenum: number }

export const getAssetsByIOPoints = (assets: TimelineCommonAsset[], refVideo: RefVideoType,
  { generateNewId, markIn, markOut, startFromZero }: OptionsType) => {
  let resultCopiedAssets: TimelineCommonAsset[] = assets
  const excludeAssetIds: string[] = []

  const inPoint = isNil(markIn) ? null : secondsToTimelineTime(markIn)
  const outPoint = isNil(markOut) ? null : secondsToTimelineTime(markOut)
  const allPointsExists = !isNil(inPoint) && !isNil(outPoint)
  const oneOfIOPointExists = isNumber(inPoint) || isNumber(outPoint)

  const fps = getFPSByRefVideo(refVideo)
  const frame = secondsToTimelineTime(1 / fps)

  if (oneOfIOPointExists) {
    resultCopiedAssets = []
    assets.forEach(asset => {
      const rebuildAsset = cloneInstanceClass(asset)

      if (generateNewId) {
        rebuildAsset.id = generateClientId()
      }

      if ((isNumber(inPoint) && !isNumber(outPoint) && (rebuildAsset.startTime >= inPoint))
        || (allPointsExists && (rebuildAsset.startTime >= inPoint && rebuildAsset.endTime <= outPoint))) {
        resultCopiedAssets.push(rebuildAsset)
      } else if (allPointsExists && (rebuildAsset.startTime < inPoint)
        && (rebuildAsset.endTime > outPoint)) {
        const diff = rebuildAsset.endTime - outPoint
        rebuildAsset.duration -= diff

        const diff2 = inPoint - rebuildAsset.startTime
        rebuildAsset.mediaStart += diff2
        rebuildAsset.duration -= diff2
        rebuildAsset.startTime = inPoint
        if (rebuildAsset.duration < frame) {
          excludeAssetIds.push(rebuildAsset.id)
        } else {
          resultCopiedAssets.push(rebuildAsset)
        }
      } else if ((allPointsExists && inRange(rebuildAsset.startTime, inPoint, outPoint))
        || (isNumber(outPoint) && !isNumber(inPoint)
        && inRange(outPoint, rebuildAsset.startTime, rebuildAsset.endTime))) {
        if (!(asset instanceof TransitionAsset)) {
          const diff = rebuildAsset.endTime - outPoint
          rebuildAsset.duration -= diff
          if (rebuildAsset.duration < frame) {
            excludeAssetIds.push(rebuildAsset.id)
          } else {
            resultCopiedAssets.push(rebuildAsset)
          }
        } else if (rebuildAsset.type === TRANSITIONS.DISSOLVE && isString(rebuildAsset.rightVideoAssetId)) {
          excludeAssetIds.push(rebuildAsset.rightVideoAssetId)
        }
      } else if ((allPointsExists && inRange(rebuildAsset.endTime, inPoint, outPoint))
        || (isNumber(inPoint) && !isNumber(outPoint)
        && inRange(inPoint, rebuildAsset.startTime, rebuildAsset.endTime))) {
        if (!(asset instanceof TransitionAsset)) {
          const diff = inPoint - rebuildAsset.startTime
          rebuildAsset.mediaStart += diff
          rebuildAsset.duration -= diff
          rebuildAsset.startTime = inPoint
          if (rebuildAsset.duration < frame) {
            excludeAssetIds.push(rebuildAsset.id)
          } else {
            resultCopiedAssets.push(rebuildAsset)
          }
        } else if (rebuildAsset.type === TRANSITIONS.DISSOLVE && isString(rebuildAsset.leftVideoAssetId)) {
          excludeAssetIds.push(rebuildAsset.leftVideoAssetId)
        }
      }
    })
  }

  const result = resultCopiedAssets
    .filter(a => !excludeAssetIds.includes(a.id))
    .sort((a, b) => a.startTime - b.startTime)

  if (startFromZero && oneOfIOPointExists) {
    const minStartTime = result[0]?.startTime || 0
    return result.map(asset => {
      // eslint-disable-next-line no-param-reassign
      asset.startTime -= minStartTime
      return asset
    })
  }
  return result
}
