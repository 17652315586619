import { setMemoSliderTime, stopPlaybackClip } from 'actions/playback'
import { HOTKEYS, PLAYER_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import { isNil, isNumber } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { selectMemoSliderTime } from 'selectors/playback'
import { selectInOutPointsMoving, selectorActivePreview } from 'selectors/preview'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { PlayerType } from 'types/common'

export const usePlayingSourceMediaFragment = ({
  seek, progress, duration, onTogglePlayPause, preview,
}: {
  seek(sec: number):void,
  progress: number, duration: number,
  onTogglePlayPause():void
  preview: PlayerType,
}) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (preview !== PLAYER_TYPE.MEDIA) {
    return
  }

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const isMovingIOPoints = useSelector(selectInOutPointsMoving)
  const activePreview = useSelector(selectorActivePreview)
  const { markIn, markOut } = useSelector((state: RootState) => state.preview.clipCreator.media)
  const { media: memoSliderTime } = useSelector(selectMemoSliderTime)

  const onStopPlaybackClip = useAction(stopPlaybackClip)
  const onSetMemoSliderTime = useAction(setMemoSliderTime)

  const play = useCallback((markIn: number) => {
    if (activePreview === PLAYER_TYPE.MEDIA) {
      if (markIn < duration) {
        onStopPlaybackClip()
        onSetMemoSliderTime(progress, PLAYER_TYPE.MEDIA)
        seek(markIn)
        onTogglePlayPause()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ progress, duration, seek, activePreview ])

  const stop = useCallback(() => {
    if (activePreview === PLAYER_TYPE.MEDIA) {
      if (!isMovingIOPoints) {
        onStopPlaybackClip()
        if (isNumber(memoSliderTime)) {
          seek(memoSliderTime)
        }
        onSetMemoSliderTime(null, PLAYER_TYPE.MEDIA)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isMovingIOPoints, memoSliderTime, seek, activePreview ])

  const playSourceMediaSegment = useCallback(() => {
    if (activePreview === PLAYER_TYPE.MEDIA) {
      isNil(memoSliderTime) ? play(markIn ?? 0) : stop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activePreview, markIn, memoSliderTime, play, stop ])

  const rewind = useCallback((secPoint: number | null) => {
    if (activePreview === PLAYER_TYPE.MEDIA) {
      if (isNumber(secPoint)) {
        onStopPlaybackClip()
        seek(secPoint)
      }
    }
  }, [ seek, onStopPlaybackClip, activePreview ])

  const rewindIn = useCallback(() => rewind(markIn), [ rewind, markIn ])
  const rewindOut = useCallback(() => rewind(markOut), [ rewind, markOut ])

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PLAY_TIMELINE_SEGMENT], playSourceMediaSegment)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.SEEK_TO_IN_POINT], rewindIn)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.SEEK_TO_OUT_POINT], rewindOut)

  useEffect(() => {
    if (activePreview === PLAYER_TYPE.MEDIA) {
      if (!isNil(memoSliderTime) && !isMovingIOPoints) {
        const maxTime = (!markOut || markOut > duration)
          ? duration
          : markOut
        if (progress >= maxTime) stop()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activePreview, markOut, progress, duration, stop, memoSliderTime ])
}
