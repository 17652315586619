import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Actions from 'actions'
import Button from 'components/base/Button'
import SettingsInput from 'components/Settings/SettingsInput'
import MenuItem from 'components/base/MenuItem'
import Select from 'components/base/Select'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import Scrollbars from '~/components/base/Scrollbars'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
// import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { TASK_STATUS, HIEROGLYPH_LANG } from '~/enums'
import { useAction } from '~/hooks'
import './NabletHld.scss'
import { getLang } from '~/helpers/getLang'
import { useOpenProject } from '~/components/Projects/hooks/useProjectItemProps/useOpenProject'


function NabletHld() {
  const hideNabletSettings = useAction(Actions.mainView.showNabletHldSettings, false)
  const { t } = useContext(TranslationContext)
  const { taskId, fileId, onnxNames,
    status, error, progress, cutLists } = useSelector(state => state.nabletHld)

  const [ targetDurationSec, setTargetDurationSec ] = useState(30)
  const initalOnnxName = onnxNames && onnxNames.length ? onnxNames[0] : ''
  const [ onnxName, setOnnxName ] = useState(initalOnnxName)
  const requestCreateTask = useAction(Actions.nabletHld.requestCreateTask,
    fileId, onnxName, targetDurationSec)
  const deleteTask = useAction(Actions.nabletHld.requestDeleteTask, taskId)
  const clearTask = useAction(Actions.nabletHld.clearTask)
  const projectName = t('NABLET_HLD_PROJECT_POSTFIX')
  const createProject = useAction(
    Actions.nabletHld.requestCreateProjects, projectName, fileId, cutLists
  )

  const openProject = useOpenProject()

  const onChangeOnnx = useCallback(e => {
    const onnxName = e.target.value
    setOnnxName(onnxName)
  }, [ setOnnxName ])

  const onChangetargetDurationSec = useCallback(e => {
    const duration = Number(e.target.value)
    setTargetDurationSec(duration)
  }, [ setTargetDurationSec ])

  const onSubmit = useCallback(() => {
    requestCreateTask()
  }, [ requestCreateTask ])

  const onCancel = useCallback(() => {
    deleteTask()
    hideNabletSettings()
  }, [ deleteTask, hideNabletSettings ])

  const [ confirm, setConfirm ] = React.useState(false)
  const onCloseClick = useCallback(() => {
    if (status && status !== TASK_STATUS.COMPLETED && status !== TASK_STATUS.ABORTED) {
      setConfirm(true)
    } else {
      hideNabletSettings()
      clearTask()
    }
  }, [ status, hideNabletSettings, setConfirm, clearTask ])

  const onConfirmationCanceled = useCallback(() => {
    setConfirm(false)
  }, [])
  const onConfirm = useCallback(() => {
    hideNabletSettings()
    deleteTask()
  }, [ hideNabletSettings, deleteTask ])
  const onCreateProject = useCallback(async e => {
    const projectId = await createProject()
    hideNabletSettings()
    clearTask()
    await openProject(e, projectId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ createProject, hideNabletSettings, clearTask ])

  return (
    <div className="nablet-hld-settings">
      <div className="nablet-hld-settings__head">
        <div className="nablet-hld-settings__top">
          <span className="nablet-hld-settings__caption">
            {t('NABLET_HLD_CAPTION')}
          </span>
          <div className="nablet-hld-settings__close" onClick={onCloseClick}>
            <CloseIcon />
          </div>
          {confirm && <Confirmation onCancel={onConfirmationCanceled} onClose={onConfirm} />}
        </div>
        <If condition={!status}>
          <Controls
            targetDurationSec={targetDurationSec}
            onChangetargetDurationSec={onChangetargetDurationSec}
            onnxName={onnxName}
            onnxNames={onnxNames}
            onChangeOnnx={onChangeOnnx}
            onSubmit={onSubmit}
          />
        </If>
        <If condition={status && status !== TASK_STATUS.COMPLETED}>
          <ControlsStatus
            onCancel={onCancel}
            status={status}
            error={error}
            progress={progress}
          />
        </If>
        <If condition={status && status === TASK_STATUS.COMPLETED}>
          <Info cutLists={cutLists} onCreateProject={onCreateProject} />
        </If>
      </div>
    </div>
  )
}

function Controls({ targetDurationSec,
  onChangetargetDurationSec,
  onnxNames,
  onnxName,
  onChangeOnnx,
  onSubmit }) {
  const { t } = useContext(TranslationContext)
  return (
    <div className="nablet-hld-settings__controls">
      <div className="nablet-hld-settings__params">
        <div className="nablet-hld-settings__onnx">
          <span className="nablet-hld-settings__label">{t('NABLET_HLD_ONNX_MODEL')}</span>
          <Select
            value={onnxName || (onnxNames.length ? onnxNames[0] : '')}
            onChange={onChangeOnnx}
          >
            {onnxNames.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`nb_hld_onnx_${index}`} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="nablet-hld-settings__duration">
          <span className="nablet-hld-settings__label">{t('NABLET_HLD_TARGET_DURATION_SEC')}</span>
          <SettingsInput
            defaultValue={targetDurationSec}
            onChange={onChangetargetDurationSec}
            min={1}
            max={Number.MAX_VALUE}
          />
        </div>
      </div>
      <div className="nablet-hld-settings__btn-start">
        <Button
          primary
          onClick={onSubmit}
          data-lang-id="NABLET_HLD_DETECTED_BTN_START"
        >
          {t('NABLET_HLD_DETECTED_BTN_START')}
        </Button>
      </div>
    </div>
  )
}

function ControlsStatus({ onCancel, status, error, progress }) {
  const { t } = useContext(TranslationContext)
  const showProgressCancel = (status
    && status !== TASK_STATUS.COMPLETED
    && status !== TASK_STATUS.ABORTED)

  function status2Text(status, error, t) {
    switch (status) {
      case TASK_STATUS.NEW:
        return `${t('NB_HLD_STATUS_TEXT_LOADING')}...`
      case TASK_STATUS.RUNNING:
        return `${t('NB_HLD_STATUS_TEXT_DETECTING')}...`
      case TASK_STATUS.DOWNLOADING:
        return `${t('NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES')}...`
      case TASK_STATUS.ABORTED: {
        return `${t('NB_HLD_STATUS_TEXT_ABORTED')}: ${error}`
      }
      default:
        return ''
    }
  }

  const statusText = status2Text(status, error, t)

  return (
    <>
      <div className="nablet-hld-settings__status">
        <If condition={showProgressCancel}>
          <div className="nablet-hld-settings__loader">
            <div className="nablet-hld-settings__loader-inner" style={{ width: `${Math.min(progress, 100)}%` }} />
          </div>
          <div className="nablet-hld-settings__btn-cancel">
            <Button className={HIEROGLYPH_LANG.includes(getLang()) ? 'fs15' : ''} onClick={onCancel} secondary>{t('NABLET_HLD_DETECTED_BTN_CANCEL')}</Button>
          </div>
        </If>

      </div>
      <StatusText text={statusText} />
    </>
  )
}

function StatusText({ text }) {
  return (
    <div className="nablet-hld-settings__text">
      <p>{text}</p>
    </div>
  )
}

function Info({ cutLists, onCreateProject }) {
  const { t } = useContext(TranslationContext)
  const intervalsCount = cutLists
    ? cutLists.reduce((count, item) => count + item.intervals.length, 0) : 0
  return (
    <>
      {intervalsCount > 0 && (
        <div className="nablet-hld-settings__btn-create-project">
          <Button
            primary
            onClick={onCreateProject}
            data-lang-id="NABLET_HLD_DETECTED_BTN_CREATE_PROJECT"
          >
            {t('NABLET_HLD_DETECTED_BTN_CREATE_PROJECT')}
          </Button>
        </div>
      )}
      <Scrollbars className="scrollbar" style={{ height: '110px' }}>

        {/* List each label and its intervals count */}
        {intervalsCount > 0 && (
          <ul className="nablet-hld-settings__info">
            <li className="nablet-hld-settings__info-item">{t('NABLET_HLD_DETECTED_INTERVALS_COUNT')}</li>
            {cutLists
              .filter(item => item.intervals.length > 0) // Filter out labels with zero intervals
              .map(item => (
                <li className="nablet-hld-settings__info-item">
                  {item.label}
                  :
                  <span>{item.intervals.length}</span>
                </li>
              ))}
          </ul>
        )}
      </Scrollbars>
    </>
  )
}

function Confirmation({ onCancel, onClose }) {
  const { t } = useContext(TranslationContext)
  return (
    <Dialog disableBackdropClick open onClose={onCancel}>
      <DialogContent>
        <DialogContentText>
          {t('NABLET_HLD_DETECTED_CLOSE_CONFIRMATION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button primary onClick={onCancel} data-lang-id="NABLET_HLD_DETECTED_BTN_NO">
          {t('NABLET_HLD_DETECTED_BTN_NO')}
        </Button>
        <Button primary onClick={onClose} autoFocus data-lang-id="NABLET_HLD_DETECTED_BTN_YES">
          {t('NABLET_HLD_DETECTED_BTN_YES')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NabletHld
