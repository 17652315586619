import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars-2'
import { TimelineScrollPositionContext } from 'components/Timeline/ScrollPositionContext'
import { getLayers, getReferenceVideoAsset, getSelectedAssets } from 'selectors'
import { getFPSByRefVideo, secondsToTimelineTime, time2Pixel, timelineTimeToSeconds } from 'Util'
import { selectorPreviewClipCreator } from 'selectors/preview'
import { isEmpty, isNil } from 'lodash'
import { ClipCreatorMarkType } from 'types/preview'
import { MARK_TYPE } from 'config/constants/preview'
import { useAction } from 'hooks/utils'
import { setInOutPointsMoving, setPreviewMarker } from 'actions/preview'
import { PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { rewind } from 'actions/timeline'
import { setMemoSliderTime, setTimelineSegmentPlaybackState } from 'actions/playback'
import { selectMemoSliderTime, selectTimelineSegmentPlaybackState } from 'selectors/playback'
import { Context as TimelineGeometryContext } from 'components/Timeline/GeometryContextProvider'
import { useScrollBarControl } from '../useScrollBarControl'

type MouseDownType = { markType: ClipCreatorMarkType | null, coordX: number | null }

export const useIOPointsTimeline = ({ scale, getTimeFromMouseEvent, scrollbarRef }: {
  scale: number,
  getTimeFromMouseEvent(e: MouseEvent): void,
  scrollbarRef: React.MutableRefObject<Scrollbars>
}) => {
  const { scrollLeft } = useContext(TimelineScrollPositionContext)
  const layers = useSelector(getLayers)
  const { timeline } = useSelector(selectorPreviewClipCreator)
  const selectedAssets = useSelector(getSelectedAssets)
  const refVideo = useSelector(getReferenceVideoAsset)
  const sliderTime = useSelector((state: RootState) => state.timeline.sliderTime)
  const { timeline: memoSliderTime } = useSelector(selectMemoSliderTime)
  const timelineSegmentPlaybackState = useSelector(selectTimelineSegmentPlaybackState)

  // This context not used, but is necessary for rerender components when the window size is changed
  const { timelineHeight } = useContext(TimelineGeometryContext)

  const onSetPreviewMarker = useAction(setPreviewMarker)
  const onRewind = useAction(rewind)
  const onSetMemoSliderTime = useAction(setMemoSliderTime)
  const onSetInOutPointsMoving = useAction(setInOutPointsMoving)
  const onSetTimelineSegmentPlaybackState = useAction(setTimelineSegmentPlaybackState)

  const leftPointRef = useRef<HTMLDivElement | null>(null)
  const rightPointRef = useRef<HTMLDivElement | null>(null)
  const clickData = useRef<MouseDownType>({ markType: null, coordX: null })

  const {
    onScrollTimelineX,
    scrollLeftInterval,
  } = useScrollBarControl(scrollbarRef)

  const leftPoint = !isNil(timeline.markIn) ? secondsToTimelineTime(timeline.markIn) : timeline.markIn
  const rightPoint = !isNil(timeline.markOut) ? secondsToTimelineTime(timeline.markOut) : timeline.markOut
  const translateLeftX = time2Pixel(leftPoint ?? 0, scale)
  const translateRightX = time2Pixel(rightPoint ?? 0, scale)
  const FRAME = 1 / getFPSByRefVideo(refVideo)

  useEffect(() => {
    const leftPoint = leftPointRef.current
    const rightPoint = rightPointRef.current

    const stopPropagation = (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const onMouseDown = (markType: ClipCreatorMarkType, e: MouseEvent) => {
      stopPropagation(e)
      if (timelineSegmentPlaybackState === PLAYBACK_STATE.PLAY) {
        onSetTimelineSegmentPlaybackState({ status: PLAYBACK_STATE.STOP })
      }
      onSetInOutPointsMoving(true)
      clickData.current = { markType, coordX: e.clientX }
      clearInterval(scrollLeftInterval.current)
      scrollLeftInterval.current = undefined
      onSetMemoSliderTime(sliderTime, PLAYER_TYPE.TIMELINE)
    }
    const onMouseUp = (e: MouseEvent) => {
      if (clickData.current.markType) {
        stopPropagation(e)
        clickData.current = { markType: null, coordX: null }
        document.body.style.cursor = 'default'
        clearInterval(scrollLeftInterval.current)
        scrollLeftInterval.current = undefined
        if (!isNil(memoSliderTime)) {
          onRewind(memoSliderTime)
          onSetMemoSliderTime(null, PLAYER_TYPE.TIMELINE)
        }
        onSetInOutPointsMoving(false)
      }
    }

    const leftPointMouseDown = (e: MouseEvent) => onMouseDown(MARK_TYPE.IN, e)
    const rightPointMouseDown = (e: MouseEvent) => onMouseDown(MARK_TYPE.OUT, e)

    const onMouseMove = (e: MouseEvent) => {
      if (clickData.current.markType && clickData.current.coordX) {
        stopPropagation(e)
        window.getSelection()?.removeAllRanges()
        document.body.style.cursor = 'ew-resize'
        const { markIn, markOut } = timeline
        let currentPoint = clickData.current.markType === MARK_TYPE.IN
          ? markIn
          : markOut
        currentPoint = timelineTimeToSeconds(getTimeFromMouseEvent(e))
        if (clickData.current.markType === MARK_TYPE.IN && markOut) {
          const maxIn = markOut - FRAME
          if (currentPoint >= maxIn) {
            currentPoint = maxIn
          }
        }
        if (clickData.current.markType === MARK_TYPE.OUT && markIn) {
          const maxOut = markIn + FRAME
          if (currentPoint <= markIn + FRAME) {
            currentPoint = maxOut
          }
        }

        onSetPreviewMarker({
          point: currentPoint,
          preview: PLAYER_TYPE.TIMELINE,
          markType: clickData.current.markType,
        })
        onScrollTimelineX(e)
        onRewind(secondsToTimelineTime(currentPoint))
      }
    }

    leftPoint?.addEventListener('mousedown', leftPointMouseDown)
    rightPoint?.addEventListener('mousedown', rightPointMouseDown)
    document.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)

    return () => {
      leftPoint?.removeEventListener('mousedown', leftPointMouseDown)
      rightPoint?.removeEventListener('mousedown', rightPointMouseDown)
      document.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sliderTime,
    memoSliderTime,
    timeline,
    getTimeFromMouseEvent,
    FRAME,
    clickData.current,
    timelineSegmentPlaybackState,
  ])

  return {
    leftPointRef,
    rightPointRef,
    countLayers: layers?.length || 0,
    leftPoint,
    rightPoint,
    translateLeftX,
    translateRightX,
    scrollLeft,
    isSelectedAsset: !isEmpty(selectedAssets),
    timelineHeight,
  }
}
