import * as ActionTypes from '../ActionTypes'

export const setFileId = fileId => ({
  type: ActionTypes.SET_HLD_FILE_ID,
  payload: {
    fileId,
  },
})

export const clearTask = () => ({
  type: ActionTypes.CLEAR_HLD_TASK,
  payload: {
  },
})
export const updateTask = ({ taskId, status, progress, error, cutLists }) => ({
  type: ActionTypes.UPDATE_HLD_TASK_STATUS,
  payload: {
    taskId, status, progress, error, cutLists,
  },
})


export const setOnnxNames = onnxNames => ({
  type: ActionTypes.SET_ONNX_NAMES,
  payload: {
    onnxNames,
  },
})
