import React, { useContext, useCallback, useState, SyntheticEvent } from 'react'
import { MediaItemMenu } from 'components/AssetPanels/Media/MediaItemMenu/MediaItemMenu'
import { useSelector } from 'react-redux'
import * as Selectors from 'selectors'
import { DRAGNDROP_TYPE, SOURCE_FILE_TYPES } from 'enums'
import { useAction, useBind, useModalDialog, useOnClickWithoutPropagation } from 'hooks'
import * as Actions from 'actions'
import { BaseAssetsListContext } from 'components/AssetPanels/BaseAssetsList'
import { ConfirmationDialog } from 'components/base/ConfirmationDialog/ConfirmationDialog'
import { MediaUsedOnDeletionError } from 'errors/MediaUsedOnDeletionError'
import { SelectDialog } from 'components/base/SelectDialog'
import Scrollbars from 'components/base/Scrollbars'
import FoldersTree from 'components/AssetPanels/Media/FoldersTree'
import styles from 'components/AssetPanels/Media/FoldersTree/folderTree.module.scss'
import { TranslationContext } from 'contexts/TranslationContext'
import { MediaAsset, UploadableAsset, VideoAsset } from 'models/Asset'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open: boolean,
  anchorEl: React.ReactNode
  onClose: () => void
  asset: MediaAsset
}

function filterMediaItems(items: UploadableAsset[]) {
  const folders = items.filter(
    item => item.type === DRAGNDROP_TYPE.FOLDER
  ).map(item => item.id)

  const files = items.filter(
    item => item.type !== DRAGNDROP_TYPE.FOLDER
  ).map(item => item.id)

  return { folders, files }
}

function GroupMediaControls({ open, anchorEl, onClose, asset }: Props) {
  const [ errorTitle, setErrorTitle ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ selectedFolder, setSelectedFolder ] = useState(null)

  const { t } = useContext(TranslationContext)

  const onnxNames = useSelector(state => Selectors.nabletHld.selectHldOnnxNames(state))
  const isNabletHldEnabled = asset instanceof VideoAsset && onnxNames.length
  const currentFolder = useSelector(state => Selectors.selectCurrentFolderId(
    state,
    SOURCE_FILE_TYPES.MEDIA
  ))
  const { selectedItems } = useContext(BaseAssetsListContext)

  const onRequestDeleteMedia = useAction(
    Actions.sourceFiles.requestDeleteMedia, selectedItems, SOURCE_FILE_TYPES.MEDIA
  )
  const deleteMediaForce = useBind(onRequestDeleteMedia, true)

  const { folders, files } = filterMediaItems(selectedItems)

  const deleteMedia = async () => {
    try {
      await onRequestDeleteMedia()
    } catch (e) {
      if (e instanceof MediaUsedOnDeletionError) {
        const { title, type } = e
        setErrorTitle(t(title))
        setErrorMessage(t(type))
        forceDeletionDialog.open()
      }
    }
  }

  const onClickCopyUrl = (e: SyntheticEvent) => {
    const currentMediaAsset = selectedItems?.find(selectItem => selectItem.id === asset.id)
    if (currentMediaAsset) {
      navigator.clipboard.writeText(currentMediaAsset.originalUrl)
    }
  }

  const onBrowseOutput = useCallback((e: SyntheticEvent) => {
    const currentMediaAsset = selectedItems?.find(selectItem => selectItem.id === asset.id)
    if (currentMediaAsset) {
      // eslint-disable-next-line no-unused-expressions
      nw?.Shell?.showItemInFolder(`${currentMediaAsset.path}`)
    }
  }, [ selectedItems, asset ])

  const deletionDialog = useModalDialog({ onConfirm: deleteMedia })
  const forceDeletionDialog = useModalDialog({ onConfirm: deleteMediaForce })

  const stopPropagation = useOnClickWithoutPropagation()

  const areThereFolders = useSelector(state => Selectors.selectAreThereFolders(
    state, { type: SOURCE_FILE_TYPES.MEDIA, selectedItems: folders }
  ))

  const onMoveMediaItemsToFolder = useAction(Actions.sourceFiles.moveMediaItemsToFolder,
    { folders,
      files,
      targetFolder: selectedFolder,
      type: SOURCE_FILE_TYPES.MEDIA })

  const foldersDialog = useModalDialog({ onConfirm: onMoveMediaItemsToFolder })
  const handleFolderSelect = useCallback(id => {
    setSelectedFolder(id)
  }, [])

  const handleClickMoveFiles = useOnClickWithoutPropagation(() => {
    foldersDialog.open()
  })

  // for Scrollbars rerender
  const [ , setForce ] = useState(false)
  const forceRerender = useCallback(() => setForce(state => !state), [])

  const openNabletHldSettings = useAction(Actions.mainView.showNabletHldSettings, true)
  const setNabletHldFileId = useAction(Actions.nabletHld.setFileId, asset.fileId)
  const onShowNabletHldDetect = useCallback(() => {
    setNabletHldFileId()
    openNabletHldSettings()
  }, [ setNabletHldFileId, openNabletHldSettings ])

  return (
    <>
      <MediaItemMenu
        anchorEl={anchorEl}
        open={open}
        showMoveTo={areThereFolders}
        onClose={onClose}
        isGroup
        showNabletHld={isNabletHldEnabled}
        isTopLayer={currentFolder === undefined}
        onClickDeleteMedia={deletionDialog.open}
        onClickMoveFiles={handleClickMoveFiles}
        onClickMoveToMedia={onMoveMediaItemsToFolder}
        onClickCopyUrl={onClickCopyUrl}
        onBrowseOutput={onBrowseOutput}
        onClickNabletHld={onShowNabletHldDetect}
      />
      {deletionDialog.active
        && (
        <ConfirmationDialog
          onClick={stopPropagation}
          onCancel={deletionDialog.cancel}
          onConfirm={deletionDialog.confirm}
          confirmBtnText={t('MEDIA_CONFIRM_BTN_DELETE')}
          closeBtnText={t('MEDIA_CANCEL_BTN_DELETE')}
          description={t('MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED')}
          buttonConfirmTitle={t('MEDIA_CONFIRM_BTN_DELETE_TOOLTIP')}
          dataLangConfirmId="MEDIA_CONFIRM_BTN_DELETE"
        />
        )}
      {forceDeletionDialog.active
        && (
        <ConfirmationDialog
          onClick={stopPropagation}
          onCancel={forceDeletionDialog.cancel}
          onConfirm={forceDeletionDialog.confirm}
          title={errorTitle}
          description={errorMessage}
          confirmBtnText={t('MEDIA_CONFIRM_BTN_DELETE')}
          closeBtnText={t('MEDIA_CANCEL_BTN_DELETE')}
          buttonConfirmTitle={t('MEDIA_CONFIRM_BTN_DELETE_TOOLTIP')}
          dataLangConfirmId="MEDIA_CONFIRM_BTN_DELETE"
        />
        )}
      {foldersDialog.active
        && (
        <SelectDialog
          title={t('MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER')}
          confirmBtnText={t('MEDIA_CONFIRM_BTN_OK')}
          onCancel={foldersDialog.cancel}
          onClick={stopPropagation}
          onConfirm={() => foldersDialog.confirm()}
          onMouseUp={stopPropagation}
          dataLangConfirmId="MEDIA_CONFIRM_BTN_OK"
          buttonConfirmTitle={t('MEDIA_CONFIRM_BTN_OK_TOOLTIP')}
        >
          <div className={styles.folderTreeContainer}>
            <Scrollbars>
              <FoldersTree
                onInit={forceRerender}
                selectedFolder={selectedFolder}
                onSelect={handleFolderSelect}
                selectedMediaItems={folders}
              />
            </Scrollbars>
          </div>
        </SelectDialog>
        )}
    </>
  )
}

export default GroupMediaControls
