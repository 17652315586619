import { PLAYER_TYPE } from 'enums'
import * as ActionTypes from './ActionTypes'
import { resetPlaybackClip } from './playback'
import { MARK_TYPE } from '~/config/constants/preview'

export const setPreviewParams = (scale, boundingRect) => ({
  type: ActionTypes.SET_PREVIEW_PARAMS,
  payload: { scale, boundingRect },
})

export const toggleSplitPreview = () => ({
  type: ActionTypes.SPLIT_PREVIEW,
  payload: {},
})

export const setActivePreview = activePreview => ({
  type: ActionTypes.SET_ACTIVE_PREVIEW,
  payload: { activePreview },
})

export const setFullScreen = fullScreen => ({
  type: ActionTypes.SET_FULLSCREEN,
  payload: { fullScreen },
})

export const setResizableParams = ({ width, height }) => ({
  type: ActionTypes.SET_RESIZABLE_PARAMS,
  payload: { width, height },
})

export const setDuration = duration => ({
  type: ActionTypes.ON_CHANGE_DURATION,
  payload: { duration },
})

export const setPreviewMarker = ({
  preview,
  point,
  markType,
  showIOPoints = true,
  updateAsset = true,
}) => ({
  type: ActionTypes.SET_PREVIEW_MARKER,
  payload: { preview, point, markType, updateAsset, showIOPoints },
})

export const resetMarkers = ({ preview, markType = null }) => ({
  type: ActionTypes.RESET_MARK,
  payload: { preview, markType },
})

export const resetNHideMarkers = (preview = null) => ({
  type: ActionTypes.RESET_N_HIDE_CLIP_MARKERS,
  payload: { preview },
})

export const setPreviewMarkers = (
  data, options
) => {
  const {
    showIOPoints,
    preview,
    updateAsset,
  } = { showIOPoints: true, preview: PLAYER_TYPE.MEDIA, updateAsset: true, ...(options ?? {}) }
  return {
    type: ActionTypes.SET_PREVIEW_MARKERS,
    payload: {
      data,
      showIOPoints,
      preview,
      updateAsset,
    },
  }
}

export const updatePreviewMarkers = assetId => ({
  type: ActionTypes.UPDATE_PREVIEW_MARKERS,
  payload: { assetId },
})

export const setInOutPointsTimelineAsset = assetId => ({
  type: ActionTypes.SET_IN_OUT_POINTS_TIMELINE_ASSET,
  payload: {
    assetId,
  },
})

export const setShowInOutPoints = (show, preview = PLAYER_TYPE.MEDIA) => ({
  type: ActionTypes.SET_SHOW_IN_OUT_POINTS,
  payload: {
    show,
    preview,
  },
})

export const setInOutPointsMoving = isMoving => ({
  type: ActionTypes.SET_IN_OUT_POINTS_MOVING,
  payload: { isMoving },
})

export const setSourcePlayerProgress = progress => ({
  type: ActionTypes.SET_SOURCE_PLAYER_PROGRESS,
  payload: { progress },
})

export const setPreviewLoading = isLoading => ({
  type: ActionTypes.SET_PREVIEW_LOADING,
  payload: { isLoading },
})

export const setPreviewMode = mode => ({
  type: ActionTypes.SET_PREVIEW_MODE,
  payload: { mode },
})

export const setCanvasPlayerBoxBounds = bounds => ({
  type: ActionTypes.SET_CANVAS_PLAYER_BOX_BOUNDS,
  payload: { bounds },
})

export const setProjectIOPoints = () => async (dispatch, getState) => {
  const { ioPoints } = getState().projectData?.activeProject ?? {}
  // reset source media playback
  dispatch(resetPlaybackClip())

  // reset prev i/o points
  dispatch(resetNHideMarkers())
  dispatch(setInOutPointsTimelineAsset(null))

  // set current project i/o points
  dispatch(setPreviewMarkers({
    [PLAYER_TYPE.TIMELINE]: {
      [MARK_TYPE.IN]: ioPoints?.markIn ?? null,
      [MARK_TYPE.OUT]: ioPoints?.markOut ?? null,
    },
  }, { preview: PLAYER_TYPE.TIMELINE, updateAsset: false }))
}
